.anfabloom #Header {
    padding: 12px 30px;
    background-color: #f8aca5;
}
.anfabloom #Header .info ,.anfabloom #Header .info a{
    color: #ffffff;
    text-decoration: none;
}
.anfabloom #Header2 li.item a:hover {
    color: #f6a09f;
    transition: all 0.3s ease-in-out;
}
.anfabloom #Contact {
    /* border-top: 2px solid #fff; */
    border-top: 0;
}
.anfabloom span.contact-popup-btn {
    color: white!important;
    background-color:#f8aca5;
}
.anfabloom .contact-popup div#form-sec {
    border: 3px solid #f8aca5;
}
.anfabloom #btn {
    background-color: #f8aca5;
}
.anfabloom h1 {
    color: #72caf1;
    border-color: #f6b6b0;
}
.anfabloom .btn {
    background-color: #f8aca5;
    color: white!important;
}
.anfabloom .btn:hover{
    opacity: 0.9;
}
.anfabloom #btn {
    color: #f8aca5!important;
}
.anfabloom .contact-popup #Contact #btn {
    color: #FFE!important;
}
.anfabloom .section-padding.Galerie {
    background: linear-gradient(to bottom, #72caf1, #72caf1, #b7e8e2, #d8e6d9, #f4c9c9, #f7d7b7, #fce7c5);
}
.anfabloom .section-padding.Galerie h2.title:before,
.anfabloom .video .section-padding h2.title:before,
.anfabloom .virtu .section-padding h2.title:before,
.anfabloom .projects .section-padding h2.title:before

{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #faefad !important;
    width: 105px;
    margin-top: 18px;
}
.anfabloom .plans h2.title{
    color: #fff ;
}
.anfabloom .projets .swiper-container h3 {
    color: white;
    font-size: 22px;
    letter-spacing: 0.7px;
    margin-top: 25px;
  }
.anfabloom .section.section-padding h2.title:before,
.anfabloom .section-padding.atouts h2.title:before, 
.anfabloom .plans h2.title:before,
.anfabloom .section-padding.maps h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #f9c7bc;
    width: 105px;
    margin-top: 18px;
}
.anfabloom .section-padding.maps,.anfabloom .plans.section-padding h2,.anfabloom .section-padding.atouts h2,.anfabloom .section-padding.maps h2{
    color: #72caf1 !important
}
.anfabloom .plans.section-padding .swiper-container h2{
    color: #000 !important
}
.anfabloom .section-padding.Galerie button.btn {
    background-color: #fff;
    color: #7ccdf1!important;
}
.anfabloom .atouts .flex-items {
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
}
.anfabloom .atouts .card .flex-items h3{
    padding-left: 0!important;
    color: #000;
    font-size: 16px!important;
    font-weight: bold;
}
.anfabloom .atouts .card img {
    float: none;
    margin-bottom: 30px;
    width: 60px;
}
.anfabloom .atouts .card p {
    text-align: center;
    margin: 0;
    padding: 0;
    color: #000;
}
.anfabloom .atouts .col-md-6 .card {
    display: flex;
    align-items: center;
    justify-content: center;
}
.anfabloom .row.align-items-center h4 {
    font-style: normal!important;
    font-weight: bold;
}
.anfabloom  h2.title:before {
    border: 2px solid #ddbd82;
}
.anfabloom .video .section.section-padding, .anfabloom .virtu .section.section-padding, .anfabloom .section.section-padding .projets {
    background: linear-gradient(to bottom, #72caf1, #72caf1, #b7e8e2, #d8e6d9, #f4c9c9, #f7d7b7, #fce7c5);
}
.anfabloom .section-padding.projets {
    background: linear-gradient(to bottom, #72caf1, #72caf1, #b7e8e2, #d8e6d9, #f4c9c9, #f7d7b7, #fce7c5);
}
.anfabloom .video .all-centre span {
   color: #fff !important;
    font-weight: bold;
}
.anfabloom .section-padding.maps h1.mb-4 {
    color: #000;
    border: 0;
    font-size: 2.5rem;
    display: contents;
}
.anfabloom .section-padding.maps p {
    color: #000;
    margin: 0 !important;
}
.anfabloom .section-padding.maps .d-flex.flex-column.align-items-center {
    height: 160px;
    justify-content: center;
    border: 1px solid #000;
}
.anfabloom .section-padding.maps .row.row-cols-2.row-cols-md-3.g-4 .col {
    padding: 0;
}
.anfabloom .section-padding.maps .row.row-cols-2.row-cols-md-3.g-4 {
    margin-top: 20px;
}
.anfabloom .section-padding.maps .col:nth-child(1) .d-flex.flex-column.align-items-center {
    border-left: 0;
    border-top: 0;
}
.anfabloom .section-padding.maps .col:nth-child(2) .d-flex.flex-column.align-items-center {
    border-top: 0;
}
.anfabloom .section-padding.maps .col:nth-child(3) .d-flex.flex-column.align-items-center {
    border-right: 0;
    border-top: 0;
}
.anfabloom .section-padding.maps .col:nth-child(4) .d-flex.flex-column.align-items-center {
    border-left: 0;
    border-bottom: 0;
}
.anfabloom .section-padding.maps .col:nth-child(5) .d-flex.flex-column.align-items-center {
    border-bottom: 0;
}
.anfabloom .section-padding.maps .col:nth-child(6) .d-flex.flex-column.align-items-center {
    border-right: 0;
    border-bottom: 0;
}
.anfabloom .section-padding.maps .section-padding.projets{
    background-color: #fff!important;
}

.anfabloom div#notregroupe-image:after,
.anfabloom .section-padding.maps .text-center:after,
.anfabloom div#notregroupe-image:before,
.anfabloom .section-padding.maps .text-center:before {
display: none;
}
.anfabloom #contact #form-sec {
    /* border-left: 2px solid #f8aca5; */
    border-left: 0;
}
.anfabloom .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.anfabloom .contact-popup p.txt {
    color: #f8aca5;
  }
.anfabloom .plans ul {
    text-align: left;
}

.anfabloom .projets .swiper-pagination-bullet{
    width: 20px !important;
    height: 20px !important;
}
.anfabloom .projets .swiper-pagination-bullet-active {
    background: #72caf1 !important;
    outline: 4px solid #faefad;
}
.anfabloom .swiper-pagination-bullet {
    background: #72caf1 !important;
    opacity: 1;
}
.anfabloom .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 7px;
}
.anfabloom .projets .swiper-slide h3{
    opacity: 0.7;
}
.anfabloom .projets .swiper-slide.swiper-slide-next h3 {
    opacity: 1;
}
.anfabloom .atouts .col-md-6:first-child .card.linear:first-child{
    background-image: linear-gradient(to bottom, #e5ecea, #eadcd4, #f0c8b9, #f4deb5, #f8f9b1)!important;
}
.anfabloom .atouts .col-md-6:nth-child(2) .card.linear:first-child {
    background-image: linear-gradient(to bottom, #96efec,#96efec, #c5d9d4, #f2c4be, #f0c8b9, #f4deb5, #f8f9b1)!important;
}
.anfabloom .atouts .col-md-6:nth-child(3) .card.linear:first-child{
    background-image: linear-gradient(to bottom, #4275f5, #8092ee, #b0a7ea, #debae7)!important;
}
.anfabloom .atouts .col-md-6:nth-child(4) .card.linear:first-child{
    background-image: linear-gradient(to bottom, #a2a0eb, #c1ade9, #e1b4d6, #e39790)!important;
}
.anfabloom .atouts .card:hover img {
    transform: rotate(360deg);
    transition: 1s ease-in-out;
}
.Galerie .swiper-button-next:after, .Galerie .swiper-button-prev:after
{
    content: " ";
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../../Images/right-arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transform: scale(0.5);
}
.Galerie .swiper-button-prev:after {
    transform: scale(0.5) rotate(180deg);
    width: -webkit-fill-available;
    height: 45px;
    top: -7px;
    left: -340px !important;
    right: initial !important;
}
.Galerie .swiper-button-next:after {
    width: -webkit-fill-available;
    height: 45px;
    top: -7px;
    right: -250px;
}
@media all and (max-width: 450px) {
    .section-padding.projets h2.title{
        color: #fff;
    }

}
@media all and (max-width: 980px) {
    .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet, .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,.section-padding.atouts span.swiper-pagination-bullet {
        background: #d8c07e !important;
        border: 2px solid #d8c07e;
    }
    .anfabloom .section-padding.maps {
        background-color: initial !important;
    }
    .anfabloom .section-padding.maps .col .d-flex.flex-column.align-items-center {
        border: 1px solid #000 !important;
    }
}