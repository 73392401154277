div#nouscontacter-section {
    padding: 80px 0 125px;
    background-color: #fff;
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
}
div#nouscontacter-section h1{
    border: 0;
    font-weight: 600;
    font-family: montserrat!important;
    margin-bottom: 10px;
}
.contact nav#Header2 img {
    display: none!important;
}
.contact div#Contact {
    display: none;
}
.contact #Header2.home-header {
    display: none;
}
.contact #form-sec {
    border: 0;
    background-color: initial;
    padding-bottom: 0;
}

.contact-form-drop .contact-dropdown {
    border-bottom: 1px solid #49505747 !important;
    background: transparent;
}

.contact input, .contact select, .contact textarea {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0!important;
    background-color: initial;
    border-color: rgba(73, 80, 87, 0.28)!important;
    padding: 0;
}
.contact #logo-sec img {
    width: 55%;
    margin-top: -20%;
    margin-bottom: 5%;
}
.contact #logo-sec p, .contact #logo-sec label {
    color: #4e5057;
    font-family: montserrat!important;
    text-align: left;
    padding-left: 10px;
    font-size: 15px;
    font-weight: 500;
}
.contact div#infocontact-section {
    padding: 0;
}
.contact div#infocontact-text img {
    width: 100%;
    height: 310px;
    left: -2.6%;
    bottom: 0;
    position: relative;
}
.contact div#infocontact-text h5 {
    font-family: montserrat!important;
    font-weight: 600;
    font-size: 24px;
}
.contact div#infocontact-text p,.contact div#infocontact-text li,.contact div#infocontact-text li a {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    font-family: open sans;
    text-decoration: none;
}
.contact div#infocontact-text ul svg {
    position: absolute;
    left: 15px;
    font-size: 25px;
}
.contact div#infocontact-text svg {
    font-size: 20px;
    color: #b7853e;
}
.contact div#infocontact-text ul li {
    margin: 10px;
    padding-left: 50px;
}
.contact div#infocontact-section #infocontact-text ul {
    left: -6%;
    position: relative;
}
.contact div#infocontact-section #infocontact-text {
    padding-top: 6%;
}