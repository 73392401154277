.gal {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
  }
  
  .gal > div {
    text-align: center;
    padding: 20px 0;
  }

  .gal > div:hover {
    transform: scale(1.02);
    box-shadow: 0 0 0 rgba(0,0,0,.5);
  }
  .plans .swiper-container {
    width: 100%;
    height: 100%;
    margin-bottom: 50px;
  }
  .plans .swiper-slide {
    text-align: center;
    font-size: 18px;
    padding: 25px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transform: scale(0.9);
  }
  .plans .swiper-slide img.d-block.w-100 {
    height: 300px;
    border-radius: 20px;
    box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.18);
    cursor: pointer;
}
.plans .swiper-slide.swiper-slide-next {
  transform: scale(1.1);
}
.plans .swiper-wrapper {
    display: -webkit-box;
    width: 100%!important;
}
.plans .swiper-button-next:before {
  content: 'Suivant';
  font-size: 20px;
  font-weight: 500;
  float: right;
  right: 0;
  position: relative;
  cursor: pointer;
  color: #fff;
  z-index: 9999999;
}
.plans .swiper-button-prev:before {
  content: 'Précédent';
  font-size: 20px;
  font-weight: 500;
  float: right;
  right: 0;
  position: relative;
  cursor: pointer;
  color: #fff;
  z-index: 9999999;
}
.plans .swiper-button-prev {
  left: -110px;
}
.plans .swiper-button-next {
  right: 75px;
}
.plans .swiper-button-disabled {
  display: none!important;
}
.text-center {
  width: 100%;
}
.modal-open .modal {
  z-index: 9999999;
}
.plans button.btn {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  margin-left: -55%;
}
.plans .swiper-button-prev,.plans .swiper-button-next{
  background-image:initial!important;

}
.plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets{display: none;}
.plans .swiper-button-next, .plans .swiper-button-prev {
  width: initial!important;
  float: right!important;
  position: relative!important;
}
  .swiper-container {
    overflow: inherit!important;}