.footer {
    position: sticky;
    left: 0;
    bottom: 0;
    right: 0;
    height: fit-content;
    width: 100%;
    background-color: black;
    color: white;
    text-align: center;
    padding: 15px;
}
.home-page .footer{
    padding: 120px 15px 15px!important;
}
.home-page.carriere .footer{
    padding: 15px!important;
}
.social-media {
    margin-left: 10px;
    color: #fff;
}

.url {
    color: white;
    list-style-type: none;
    text-align: left;
    font-size: 11px;
}

a:hover {
    text-decoration: none;
    color:#b5a282
}

.smedia-icon {
    font-size: 24px
}