.ricoflor-palmes #Header {
    padding: 12px 30px;
    background-color: #c97953;
}
.ricoflor-palmes #Header .info,.ricoflor-palmes #Header .info a {
    color: #ffffff;
    text-decoration: none;
}
.ricoflor-palmes #Header2 li.item a:hover {
    color: #c97953;
    transition: all 0.3s ease-in-out;
}
.ricoflor-palmes #Contact {
    /* border-top: 2px solid #fff; */
    border-top: 0;
}
.ricoflor-palmes span.contact-popup-btn {
    color: white!important;
    background-color: #c97953;
}
.ricoflor-palmes .contact-popup div#form-sec {
    border: 3px solid #c97953;
}
.ricoflor-palmes #btn {
    background-color: #c97953;
}
.ricoflor-palmes h1 {
    color: #c97953;
    font-size: 25px;
    border-bottom-color: rgb(143, 200, 118);
}
.ricoflor-palmes .btn {
    background-color: #c97953;
    color: white;
}
.ricoflor-palmes .section-padding.Galerie {
    background-color: #c97953!important;
}
.ricoflor-palmes .section-padding.Galerie h2.title:before ,
.ricoflor-palmes .video .section.section-padding h2.title:before,
.ricoflor-palmes .virtu .section.section-padding h2.title:before,
.ricoflor-palmes .section-padding.projets h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #8dc774;
    width: 105px;
    margin-top: 18px;
}
.ricoflor-palmes .section-padding.Galerie button.btn {
    background-color: #8dc774;
    color: #fff;
}
.ricoflor-palmes .plans.section-padding h2.title:before,
.ricoflor-palmes .section-padding.atouts h2.title:before ,
.ricoflor-palmes .section-padding.maps h2.title:before {
    border: 2px solid #8dc774;
}
.ricoflor-palmes .plans.section-padding{
    background-color: #fff;

}
.ricoflor-palmes .video .section.section-padding {
    background-color: #c97953;
}
.ricoflor-palmes .video .all-centre span {
    color: rgb(255, 255, 255);
    font-weight: bold;
}
.ricoflor-palmes .virtu .section.section-padding,.ricoflor-palmes .section-padding.projets{
    background-color: #c97953!important;
}
.ricoflor-palmes #contact #form-sec {
    /* border-left: 2px solid #c97953; */
    border-left:0;
}
.ricoflor-palmes .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.ricoflor-palmes .contact-popup p.txt {
    color: #c97953;
  }
  .ricoflor-palmes div#notregroupe-image:before,.ricoflor-palmes .section-padding.maps .text-center:before {
    background-image: url(../../../Images/vert.png);
    right: -145px;
    top: -130px;
  }
  .ricoflor-palmes div#notregroupe-image:after,.ricoflor-palmes .section-padding.maps .text-center:after {
    background-image: url(../../../Images/vert.png);
    transform: rotate(180deg);
    bottom: -145px;
  }
  .ricoflor-palmes div#notregroupe-image:before,.ricoflor-palmes .section-padding.maps .text-center:after  {
    width: 80%;
    height: 80%;
    transform: scale(0.9);
  }
  .ricoflor-palmes div#notregroupe-image:after,.ricoflor-palmes .section-padding.maps .text-center:before{
    width: 100%;
    height: 100%;
  }
  .ricoflor-palmes .section-padding.maps .text-center:after  {
      left: -22%;
  }
 .ricoflor-palmes div#notregroupe-image:after {
 left: -37%;
}

  @media all and (min-width: 980px) {
  .ricoflor-palmes div#notregroupe-image:after{
        left: -60px;
        width: 100%;
        height: 100%;
        transform: scale(0.9) rotate(0deg);
  }
  .ricoflor-palmes div#notregroupe-image:before {
      width: 100% !important;
      height: 100% !important;
      transform: scale(0.9);
  }
  body.ricoflor-palmes .section-padding.desc {
      padding: 100px 0;
  }
}