.magic-house-beach #Header {
    padding: 12px 30px;
    background-color: #facd89;
}
.magic-house-beach #Header .info ,.magic-house-beach #Header .info a{
    color: #ffffff;
    text-decoration: none;
}
.magic-house-beach #Header2 li.item a:hover {
    color: #facd89;
    transition: all 0.3s ease-in-out;
}
.magic-house-beach #Contact {
    /* border-top: 2px solid #fff; */
    border-top: 0;
}
.magic-house-beach span.contact-popup-btn {
    color: white!important;
    background-color:#fcb950;
}
.magic-house-beach .contact-popup div#form-sec {
    border: 3px solid #facd89;
}
.magic-house-beach #btn {
    background-color: #00adee;
}
.magic-house-beach h1 {
    color: #296EA7;
    border-color: #f8aa3d;
}
.magic-house-beach .btn {
    background-color: #296EA7;
    color: white;
}
.magic-house-beach .section-padding.Galerie {
    background-color: #facd89!important;
}
.magic-house-beach .section-padding.Galerie h2.title:before,
.magic-house-beach .video .section-padding h2.title:before,
.magic-house-beach .virtu .section-padding h2.title:before,
.magic-house-beach .projects .section-padding h2.title:before

{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #537181 !important;
    width: 105px;
    margin-top: 18px;
}
.magic-house-beach .plans h2.title{
    color: #fff ;
}
.magic-house-beach .projets .swiper-container h3 {
    color: white;
    font-size: 22px;
    letter-spacing: 0.7px;
    margin-top: 25px;
  }
.magic-house-beach .section.section-padding h2.title:before,
.magic-house-beach .section-padding.atouts h2.title:before, 
.magic-house-beach .plans h2.title:before,
.magic-house-beach .section-padding.maps h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #f8aa3d;
    width: 105px;
    margin-top: 18px;
}
.magic-house-beach .section-padding.atouts  h2 {
    color: #296EA7 !important
}
.magic-house-beach .section-padding.maps,.magic-house-beach .plans.section-padding h2,.magic-house-beach .section-padding.maps h2{
    color: #296EA7 !important
}
.magic-house-beach .section-padding.Galerie button.btn {
    background-color: #fff;
    color: #296EA7;
}
.magic-house-beach  h2.title:before {
    border: 2px solid #537181;
}
.magic-house-beach .video .section.section-padding, .magic-house-beach .virtu .section.section-padding, .magic-house-beach .section.section-padding .projets {
    background-color: #facd89 !important;
}
.magic-house-beach .video .all-centre span {
   color: #296EA7 !important;
    font-weight: bold;
}
.magic-house-beach .section-padding.maps .section-padding.projets{
    background-color: #fff!important;
}
.magic-house-beach #contact #form-sec {
    /* border-left: 2px solid #facd89; */
    border-left: 0;
}
.magic-house-beach .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.magic-house-beach .contact-popup p.txt {
    color: #facd89;
  }
  .magic-house-beach div#notregroupe-image:before,.magic-house-beach .section-padding.maps .text-center:before {
    background-image: url(../../../Images/starts-mbh.png);
    background-size: contain;
  }
  .magic-house-beach div#notregroupe-image:after,.magic-house-beach .section-padding.maps .text-center:after {
    background-image: url(../../../Images/starts-mbh.png);
    background-size: contain;
  }
  .magic-house-beach .section-padding.maps .text-center:after, 
  .magic-house-beach div#notregroupe-image:before  {
    width: 40%;
    height: 40%;

  }
  .magic-house-beach .section-padding.maps .text-center:after {
    transform: rotate(100deg);
    left: -150px;
    bottom: -100px;
}
  .magic-house-beach div#notregroupe-image:after,.magic-house-beach .section-padding.maps .text-center:before{
    width: 20%;
    height: 40%;
    bottom: -125px;
    transform: rotate(-120deg);
    left: -35px;
  }
  .magic-house-beach .section-padding.maps .text-center:before,
  .magic-house-beach div#notregroupe-image:before {
    right: 5px;
    top: -95px;
}

  .magic-house-beach .section-padding.maps .text-center:after  {
      left: -22%;

  }
  .magic-house-beach .section-padding.maps .text-center:before {
    width: 56%;
    height: 40%;
    left: initial;
    right: -290px;
    bottom: -50px;
    transform: rotate(-230deg);
} .magic-house-beach .plans ul {
    text-align: left;
}