.section {
  background-color: grey;
  height: auto;
  position: relative;
}
.video {
  position: relative;
}
.row.video {
  margin-bottom: 40px;
}
.bar-title {
  width: 120px;
  height: 1px;
  border-bottom: solid rgb(183, 133, 62) 4px;
  float: left;
}
.zone-title {
  position: relative;
  left: 0;
  top: 0;
  margin-top: 50px;
  margin-bottom: 35px;
}
h2.title:before {
  content: "";
  position: absolute;
  left: 0;
  border: 2px solid rgb(183, 133, 62);
  width: 105px;
  margin-top: 18px;
}
.title {
  padding-left: 120px;
  color: white;
  text-transform: uppercase;
}
video {
  width: 100%;
}
h2.span {
  text-transform: uppercase;
}
.all-centre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-transform: uppercase;
  text-align: center;
}
.all-centre span {
  color: rgb(183, 133, 62);
  font-weight: bold;
}
.virtu img.d-block.w-100 {
  width: 60%!important;
  margin: 0 auto;
}