.garden-bay #Header {
    padding: 12px 30px;
    background-color: #16c1f3;
}
.garden-bay #Header .info,.garden-bay #Header .info a {
    color: #ffffff;
    text-decoration: none;
}
.garden-bay #Header2 li.item a:hover {
    color: #16c1f3;
    transition: all 0.3s ease-in-out;
}
.garden-bay #Contact {
    /* border-top:2px solid rgb(255, 255, 255);   */
    border-top:0;  
}
.garden-bay span.contact-popup-btn {
    color: white!important;
    background-color: rgb(0, 173, 238);
}
.garden-bay .contact-popup div#form-sec {
    border: 3px solid #16c1f3;
}
.garden-bay #btn {
    background-color: #85c443;
}
.garden-bay h1 {
    color: rgb(0, 173, 238);
    border-color: #ffd164;
}
.garden-bay .btn {
    background-color: #85c443;
    color: white!important;
}
.garden-bay .section-padding.Galerie {
    background-color: #16c1f3!important;
}
.garden-bay .section-padding.Galerie h2.title:before ,
.garden-bay .video .section.section-padding h2.title:before,
.garden-bay .virtu .section.section-padding h2.title:before,
.garden-bay .section-padding.projets h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #ffd164;
    width: 105px;
    margin-top: 18px;
}
.garden-bay .section-padding.Galerie button.btn {
    background-color: #fff;
    color: #85c443!important;
}
.garden-bay .plans.section-padding h2.title:before,
.garden-bay .section-padding.atouts h2.title:before ,
.garden-bay .section-padding.maps h2.title:before {
    border: 2px solid #ffd164;
}
.garden-bay .plans.section-padding{
    background-color: #fff;

}
.garden-bay .video .section.section-padding {
    background-color: #16c1f3;
}
.garden-bay .video .all-centre span {
    color: rgb(255, 255, 255);
    font-weight: bold;
}
.garden-bay .virtu .section.section-padding,.garden-bay .section-padding.projets{
    background-color: #16c1f3!important;
}
.garden-bay #contact #form-sec {
    /* border-left: 2px solid #16c1f3; */
    border-left: 0;
}
.garden-bay .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.garden-bay .contact-popup p.txt {
    color: #16c1f3;
  }
  .garden-bay .livrable {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.garden-bay .livrable p {
    font-size: 16px;
    border: 2px solid #ffd164;
    padding: 8px 25px;
    color: #85c443;
    font-family: montserrat!important;
    font-weight: 600;
}
.garden-bay div#notregroupe-image:before,.garden-bay .section-padding.maps .text-center:before {
    background-image: url(../../../Images/flowers.png);
  }
  .garden-bay div#notregroupe-image:after,.garden-bay .section-padding.maps .text-center:after {
    background-image: url(../../../Images/flowers.png);
  }
  .garden-bay div#notregroupe-image:before,.garden-bay .section-padding.maps .text-center:after  {
    width: 80%;
    height: 80%;
    transform: scale(0.9);
  }
  .garden-bay div#notregroupe-image:after,.garden-bay .section-padding.maps .text-center:before{
    width: 100%;
    height: 100%;
  }
  .garden-bay .section-padding.maps .text-center:after  {
      left: -22%;
  }