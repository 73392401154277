@import "./components/projet/blue-water-house/index.css";
@import "./components/projet/les-perles-de-bentriaa/index.css";
@import "./components/projet/garden-bay/index.css";
@import "./components/projet/the-sand-house/index.css";
@import "./components/projet/ricoflor-palmes/index.css";
@import "./components/projet/magic-house/index.css";
@import "./components/projet/magic-house-beach/index.css";
@import "./components/projet/16eme-angle/index.css";
@import "./components/projet/AnfaBloom/index.css";
@import url('https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');

.App{
    margin: 0;
    padding: 0;
}
body {
    counter-reset: section;
}
textarea {
    resize: none!important;
}