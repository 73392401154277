@import url('https://fonts.googleapis.com/css?family=Great+Vibes&display=swap');
h1 {
    color: rgb(183, 133, 62);
    border-bottom: 3px solid;
    font-size: 30px;
    width: max-content;
    margin-bottom: 30px;
}

h3{
    color: rgb(183, 133, 62);
}

.btn{
    background-color:rgb(183, 133, 62) ;
    color: white;
}
.align-items-center button.btn {
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 500;
    margin: 0 auto;
    display: flow-root;
}
.align-items-center button.btn i {
    padding-right: 6px;
}
hr{
    border: 4px solid rgb(183, 133, 62);
    width: 400px;
  
}
.smallhr{
    border: 2px solid rgb(183, 133, 62);
    width: 100px;
}

#vision-image{
   
    display: block;
    text-align: center;
}

#vision-section{
    padding: 50px;
}
#vision-text{
    margin-top: 100px !important;
    margin-bottom: 60px !important;
    padding-right: 47px !important;
    text-align: justify;
}

#notregroupe-image{
    display: block;
    text-align: center;
}#notregroupe-section{
    padding: 50px;
}

#motpresident-image{
    display: block;
    text-align: center;
}

#motpresident-section{

    padding: 50px;
}

#engagements-section{
    background-color: whitesmoke;
    transform: translateY(-50px);
    box-shadow: 0 0 20px 0px black;

}
#engagements-section .row {
    padding: 0 30px 30px;
}
#engagements-section .row .title {
    padding-left: 0;margin-top: 15px;}
    #engagements-section .row .title h3 {
        font-size: 20px;
        border-bottom: 2px solid #212529;
        width: fit-content;
        margin: 20px auto;
        padding-bottom: 10px;
    }
    #engagements-section h1 {
        margin: 0 auto;
        color: #b7853e;
        border: 0;
        padding-top: 45px;
        font-weight: 700;
        letter-spacing: 0.8px;
    }
    #engagements-section .row img.d-block.w-100 {
        width: 23%!important;
        margin: 0 auto;
    }
.carousel-caption{
    bottom: 250px;
    
}

.carousel-item{
    background-size: cover;
    height: 60hv;
    max-height: 600px;
}
.home-page.politique nav#Header2 img {
    display: none!important;
}
.home-page.politique div#notregroupe-image:before ,.home-page.politique div#notregroupe-image:after{
    display: none;
}
.home-page.politique h1 {
    border: 0;
    font-weight: 600;
    font-family: montserrat!important;
    margin-bottom: 10px;
}
.home-page.politique h4 {
    font-family: montserrat!important;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.5;
}
.home-page.politique #vision-section p,.home-page.politique #notregroupe-section p,.home-page.politique #motpresident-section p {
    color: #999999;
}
.home-page.politique hr {
    border: 2px solid rgb(183, 133, 62);
    width: 20%;
}
.home-page.politique div#notregroupe-image img {
    width: 445px;
}