.les-perles-de-bentriaa #Header {
    padding: 12px 30px;
    background-color: #358fa7;
}
.les-perles-de-bentriaa #Header .info ,.les-perles-de-bentriaa #Header .info a{
    color: #ffffff;
    text-decoration: none;
}
.les-perles-de-bentriaa #Header2 li.item a:hover {
    color: #358fa7;
    transition: all 0.3s ease-in-out;
}
.les-perles-de-bentriaa #Contact {
    border-top: 0;
}
.les-perles-de-bentriaa span.contact-popup-btn {
    color: white!important;
    background-color: #358fa7;
}
.les-perles-de-bentriaa .contact-popup div#form-sec {
    border: 3px solid #358fa7;
}
.les-perles-de-bentriaa #btn {
    background-color: #358fa7;
}
.les-perles-de-bentriaa h1 {
    color: #358fa7;
}
.les-perles-de-bentriaa .btn {
    background-color: #358fa7;
    color: white;
}
.les-perles-de-bentriaa .section-padding.Galerie {
    background-color: #358fa7!important;
}
.les-perles-de-bentriaa .section-padding.Galerie h2.title:before ,
.les-perles-de-bentriaa .video .section.section-padding h2.title:before,
.les-perles-de-bentriaa .virtu .section.section-padding h2.title:before,
.les-perles-de-bentriaa .section-padding.projets h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid rgb(255, 255, 255);
    width: 105px;
    margin-top: 18px;
}
.les-perles-de-bentriaa .section-padding.Galerie button.btn {
    background-color: #fff;
    color: #358fa7;
}
.les-perles-de-bentriaa .plans.section-padding h2.title:before,
.les-perles-de-bentriaa .section-padding.atouts h2.title:before ,
.les-perles-de-bentriaa .section-padding.maps h2.title:before {
    border: 2px solid #358fa7;
}
.les-perles-de-bentriaa .plans.section-padding{
    background-color: #fff;

}
.les-perles-de-bentriaa .video .section.section-padding {
    background-color: #358fa7;
}
.les-perles-de-bentriaa .video .all-centre span {
    color: rgb(255, 255, 255);
    font-weight: bold;
}
.les-perles-de-bentriaa .virtu .section.section-padding,.les-perles-de-bentriaa .section-padding.projets{
    background-color: #358fa7!important;
}
.les-perles-de-bentriaa #contact #form-sec {
    /* border-left: 2px solid #358fa7; */
    border-left: 0;
}
.les-perles-de-bentriaa .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.les-perles-de-bentriaa .contact-popup p.txt {
    color: #358fa7;
  }
  .les-perles-de-bentriaa div#notregroupe-image:before,.les-perles-de-bentriaa .section-padding.maps .text-center:before {
    background-image: url(../../../Images/ball.png);
  }
  .les-perles-de-bentriaa div#notregroupe-image:after,.les-perles-de-bentriaa .section-padding.maps .text-center:after {
    background-image: url(../../../Images/ball.png);
  }
  .les-perles-de-bentriaa div#notregroupe-image:before,.les-perles-de-bentriaa .section-padding.maps .text-center:after  {
    width: 80%;
    height: 80%;
    transform: scale(0.9);
  }
  .les-perles-de-bentriaa div#notregroupe-image:after,.les-perles-de-bentriaa .section-padding.maps .text-center:before{
    width: 100%;
    height: 100%;
  }
  .les-perles-de-bentriaa .section-padding.maps .text-center:after  {
      left: -22%;
  }