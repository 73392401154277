.the-sand-house #Header {
    padding: 12px 30px;
    background-color: #d8c07e;
}
.the-sand-house #Header .info ,.the-sand-house #Header .info a{
    color: #ffffff;
    text-decoration: none;
}
.the-sand-house #Header2 li.item a:hover {
    color: #00adee;
    transition: all 0.3s ease-in-out;
}
.the-sand-house #Contact {
    /* border-top: 2px solid #fff; */
    border-top: 0;
}
.the-sand-house span.contact-popup-btn {
    color: white!important;
    background-color: rgb(0, 173, 238);
}
.the-sand-house .contact-popup div#form-sec {
    border: 3px solid #d8c07e;
}
.the-sand-house .contact-popup .inpt {
    border: 2px solid #ced4da!important;
    border-color: #d8c07e !important;
}
.the-sand-house #btn {
    background-color: #00adee;
}
.the-sand-house h1 {
    color: #d8c07e;
}
.the-sand-house .btn {
    background-color: #00adee;
    color: white;
}
.the-sand-house .section-padding.Galerie {
    background-color: #fff!important;
}
.the-sand-house .section-padding.Galerie h2.title {
    color: #000000;
}
.the-sand-house .section-padding.Galerie h2.title:before ,
.the-sand-house .video .section.section-padding h2.title:before,
.the-sand-house .virtu .section.section-padding h2.title:before,
.the-sand-house .section-padding.projets h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #d8c07e;
    width: 105px;
    margin-top: 18px;
}
.the-sand-house .section-padding.Galerie button.btn {
    background-color: #00adee;
    color: #fff;
}
.the-sand-house .atouts h2.title{
    color: #fff;
}
.the-sand-house .section-padding.Galerie .swiper-button-next:before ,.the-sand-house .section-padding.Galerie .swiper-button-prev:before {
    color: #00adee;}
.the-sand-house .plans.section-padding h2.title:before,
.the-sand-house .section-padding.atouts h2.title:before ,
.the-sand-house .section-padding.maps h2.title:before {
    border: 2px solid #d8c07e;
}
.the-sand-house .plans.section-padding{
    background-color: #fff;

}
.the-sand-house .video .section.section-padding {
    background-color:#838383
}
.the-sand-house .video .all-centre span {
    color: rgb(255, 255, 255);
    font-weight: bold;
}
.the-sand-house .virtu .section.section-padding,.the-sand-house .section-padding.projets{
    background-color: #838383!important;
}
.the-sand-house #contact #form-sec {
    /* border-left: 2px solid #00adee; */
    border-left: 0;
}
.the-sand-house .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.the-sand-house .contact-popup p.txt {
    color: #00adee;
  }

  .the-sand-house .video:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 10%;
    background-color: #d8c07e;
    height: 14px;
    width: 23%;
}
.the-sand-house .row.video h2 span {
    color: #d8c07e;
}
.the-sand-house .row.video:after {
    display: none;
}


