#Header{
    padding: 12px 30px;
    background-color: black;
}
#Header2{
    padding: 30px;
    background-color: #fff;
}
#Header2 img.d-block {
    margin: 0 auto;
    position: absolute;
    right: 0;
    left: 0;
    width: 15%;
    background-color: #fff;
    border-radius: 35%;
    margin-top: 85px;
    padding: 10px;
    z-index: 99;
}
#Header2 li.item a {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}
#Header2 li.item a:hover {
    color: coral;
    transition: all 0.3s ease-in-out;
}
#Header .social-media {
    margin: 0 12px;
    color: #fff;
    font-size: 17px;
}
#Header .info {
    color: #b5a282;
    font-size: 13px;
    margin-left: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
}
#Header .info a {
    color: #b5a282;
    text-decoration: none;
}
nav#Header.home-header li.nav-item.info svg {
    background-color: #28241d;
    color: #fff;
    padding: 7px;
    width: 25px;
    height: 25px;
    font-size: 7px!important;
    border-radius: 50%;
    top: 6px;
    position: relative;
    left: -2px;
}
nav#Header.home-header li.item:last-child svg {
    color: #fff;
    cursor: pointer;
    background-color: #313c46;
    padding: 7px;
    font-size: 27px;
    border-radius: 50%;
    position: absolute;
    right: 18px;
}
nav#Header.home-header .info {
    top: -4px;
    position: relative;
}
.link{
    color: white;
    text-decoration: none;
}
.link:hover {
    outline: none;
    color: coral;
    text-decoration: none;
}
.link:focus {
    outline: none;
    color: coral;
    text-decoration: none;
}
.link:active {
    outline: none;
    color: coral;
    text-decoration: none;
}

.item{
    margin-left: 30px;
}
nav#Header.home-header li.item a {
    font-size: 14px;
    font-family: montserrat!important;
}

nav#Header.home-header li.item a:active,nav#Header.home-header li.item a:focus{
    color: #977a3c;
}
.center {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 50% !important;
  }

/* Menu Animation */
#Header.mobile ul.ml-auto {
    position: absolute; /* Ensure proper positioning */
    left: -75%; /* Hidden state */
    transition: left 0.3s ease-in-out;
}

/* Icon Rotation */
#Header.mobile img.d-block {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg); /* Default state */
}

#Header.mobile ul.ml-auto.rotate-active ~ img.d-block {
    transform: rotate(-90deg); /* Rotated state */
}

#Header.mobile ul.ml-auto.rotate-reset ~ img.d-block {
    transform: rotate(0deg); /* Reset to default */
}
#Header.mobile ul.ml-auto.rotate-active {
    left: 0!important; /* Rotated state */
}

#Header.mobile ul.ml-auto.rotate-reset {
    left: -75%; /* Reset to default */
}