body.merci .zone-title {
    margin: 0 auto;
}
body.merci .remerciements h2.title {
    color: #b7853e;
    text-align: center;
    font-size: 26px;
    border: 0;
    line-height: 1.5;
    padding: 0;
}
body.merci h2.title {
    padding: 0;
}
body.merci .remerciements h4 {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 20px;
    margin: 7% 0;
}
body.merci h2.title:before {
    display: none;
}
body.merci nav#Header2 img.d-block {
    display: none!important;
}
body.merci .remerciements a.btn {
    margin: 0 auto;
    justify-content: end;
    display: flex;
    width: fit-content;
    padding: 8px 25px;
    color: #fff;
    font-family: montserrat!important;
}
body.merci .section-padding.projets {
    padding: 7% 0;
}
body.merci .section-padding.projets .swiper-pagination-bullet{
    background-color: #fff!important;
}
body.merci .section-padding.projets .zone-title {
    margin: 0 8%;
}
body.merci .section-padding.projets h2.title:before {
    display: block;
    left: -52%;
}
.home-page.merci .swiper-slide a {
    display: block;
    background-color: initial;
}