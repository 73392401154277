div#carriere-section {
    padding: 5%;
}
div#carriere-section h1 {
    font-family: montserrat!important;
    font-weight: 600;
    border: 0;
    margin: 0;
    margin-left: 12%;
}
.carriere nav#Header2 img.d-block {
    left: -3%;
    right: initial;
    width: 8%;
    margin-top: 12%;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.21);
    padding: 0;
}
.carriere #Contact,.carriere span.contact-popup-btn {
    display: none;
}
.carriere-form {
    padding: 0 0 50px 50px  ;
}
.carriere-form input {
    width: 100%;
    padding: 15px;
    font-family: montserrat!important;
    margin-bottom: 8px;
}
.carriere-form p {
    font-size: 16px;
    margin-bottom: 35px;
    color: rgb(102, 102, 102);
    font-family: montserrat!important;
    font-weight: 600;
}
.carriere-form button.btn {
    width: 100%;
    padding: 14px;
    color: #fff;
    background-color: #977a3c;
}
.carriere-form .infos {
    padding: 50px;
}
.carriere-form .infos h1 {
    margin: 0 auto;
    color: #fff;
    border-color: #977a3c;
    font-family: montserrat!important;
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.5px;
}
.carriere-form .infos ul {
    margin: auto;
    text-align: center;
    color: #fff;
    padding: 50px;
}
.carriere-form .infos ul svg {
    margin: 0 auto;
    font-size: 40px;
    color: #977a3c;
}
.carriere-form .infos ul li {
    margin: 12px;
    font-size: 17px;
    font-family: montserrat!important;
}
.carriere-form .infos ul li a{
    color: #fff;
}
.carriere-form input#cv, .carriere-form input#lettre {
    display: none;
}
.carriere-form label.cv, .carriere-form label.lettre {
    background-color: #977a3c;
    padding: 0 2px 15px;
    width: 58%;
    margin: 0 auto;
    text-align: center;
    font-size: 85px;
    color: #fff;
    border-radius: 25px;
    line-height: 1;
    cursor: pointer;
    box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.22);
}
label.title-label {
    font-size: 18px;
    color: #666;
    font-weight: 600;
    width: 100%;
}
.row.carriere-form .col-md-6.form {
    padding-right: 50px;
}
.progress {
    width: 150px;
    height: 150px;
    background: none;
    position: relative;
  }
  
  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 6px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  
  .progress .progress-left {
    left: 0;
  }
  
  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 6px;
    border-style: solid;
    position: absolute;
    top: 0;
  }
  
  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }
  
  .progress .progress-right {
    right: 0;
  }
  
  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  
  .progress .progress-value {
    position: absolute;
    top: 0;
    left: 0;
  }
  .rounded-lg {
    border-radius: 1rem;
  }
  
  .text-gray {
    color: #aaa;
  }
  .progress.mx-auto {
    position: absolute;
    margin-top: -40%;
    transform: scale(0.6);
    left: 26%;
}
.border-primary {
    border-color: rgb(253, 218, 101)!important;
}
.h2.font-weight-bold {
    color: #fff;
}
.carriere .footer {
    color: rgb(113, 113, 113);
}
.icon-upload {
  width: 84px;
  background-color: #977a3c;
  padding: 12px;
  border-radius: 5px;
}
div.letter, div.cv  {
  /* background-color: #977a3c; */
    /* padding: 0 2px 15px; */
    width: 58%;
    margin: 0 auto;
    text-align: center;
    font-size: 85px;
    border-radius: 25px;
    line-height: 1;
}