._16eme-angle #Header {
    padding: 12px 30px;
    background-color: #061b31;
}
._16eme-angle #Header .info ,._16eme-angle #Header .info a{
    color: #ffffff;
    text-decoration: none;
}
._16eme-angle #Header2 li.item a:hover {
    color: #061b31;
    transition: all 0.3s ease-in-out;
}
._16eme-angle #Contact {
    /* border-top: 2px solid #fff; */
    border-top: 0;
}
._16eme-angle span.contact-popup-btn {
    color: white!important;
    background-color:#061b31;
}
._16eme-angle .contact-popup div#form-sec {
    border: 3px solid #061b31;
}
._16eme-angle #btn {
    background-color: #061b31;
}
._16eme-angle h1 {
    color: #061b31;
    border-color: #ddbd82;
}
._16eme-angle .btn {
    background-color: #061b31;
    color: white!important;
}
._16eme-angle .btn:hover{
    opacity: 0.9;
}
._16eme-angle #btn {
    color: #061b31!important;
}
._16eme-angle .contact-popup #Contact #btn {
    color: #FFE!important;
}
._16eme-angle .section-padding.Galerie {
    background-color: #061b31!important;
}
._16eme-angle .section-padding.Galerie h2.title:before,
._16eme-angle .video .section-padding h2.title:before,
._16eme-angle .virtu .section-padding h2.title:before,
._16eme-angle .projects .section-padding h2.title:before

{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #ddbd82 !important;
    width: 105px;
    margin-top: 18px;
}
._16eme-angle .plans h2.title{
    color: #fff ;
}
._16eme-angle .projets .swiper-container h3 {
    color: white;
    font-size: 22px;
    letter-spacing: 0.7px;
    margin-top: 25px;
  }
._16eme-angle .section.section-padding h2.title:before,
._16eme-angle .section-padding.atouts h2.title:before, 
._16eme-angle .plans h2.title:before,
._16eme-angle .section-padding.maps h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #ddbd82;
    width: 105px;
    margin-top: 18px;
}
._16eme-angle .section-padding.atouts  h2 {
    color: #061b31 !important
}
._16eme-angle .section-padding.maps,._16eme-angle .plans.section-padding h2,._16eme-angle .section-padding.maps h2{
    color: #061b31 !important
}
._16eme-angle .section-padding.Galerie button.btn {
    background-color: #fff;
    color: #061b31!important;
}
._16eme-angle .row.align-items-center h4 {
    font-style: normal!important;
    font-weight: bold;
}
._16eme-angle  h2.title:before {
    border: 2px solid #ddbd82;
}
._16eme-angle .video .section.section-padding, ._16eme-angle .virtu .section.section-padding, ._16eme-angle .section.section-padding .projets {
    background-color: #061b31 !important;
}
._16eme-angle .video .all-centre span {
   color: #6b6757 !important;
    font-weight: bold;
}
._16eme-angle .section-padding.maps .section-padding.projets{
    background-color: #fff!important;
}
._16eme-angle #contact #form-sec {
    /* border-left: 2px solid #061b31; */
    border-left: 0;
}
._16eme-angle .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
._16eme-angle .contact-popup p.txt {
    color: #061b31;
  }
  ._16eme-angle div#notregroupe-image:before,._16eme-angle .section-padding.maps .text-center:before {
    background-image: url(../../../Images/motifs-16angle.svg);
    background-size: contain;
  }
  ._16eme-angle div#notregroupe-image:after,._16eme-angle .section-padding.maps .text-center:after {
    background-image: url(../../../Images/motifs-16angle.svg);
    background-size: contain;
  }
  ._16eme-angle .section-padding.maps .text-center:after, 
  ._16eme-angle div#notregroupe-image:before  {
    width: 40%;
    height: 40%;
    scale: 1.5;
  }
  ._16eme-angle .section-padding.maps .text-center:after {
    transform: rotate(100deg);
    left: -150px;
    bottom: -100px;
}
  ._16eme-angle div#notregroupe-image:after,._16eme-angle .section-padding.maps .text-center:before{
    width: 20%;
    height: 40%;
    bottom: -75px;
    transform: rotate(-120deg);
    left: -15px;
    scale: 1.5;
  }
  ._16eme-angle .section-padding.maps .text-center:before,
  ._16eme-angle div#notregroupe-image:before {
    right: 85px;
    top: -95px;
}

  ._16eme-angle .section-padding.maps .text-center:after  {
      left: -22%;

  }
  ._16eme-angle .section-padding.maps .text-center:before {
    width: 56%;
    height: 40%;
    left: initial;
    right: -290px;
    bottom: -50px;
    transform: rotate(-230deg);
} ._16eme-angle .plans ul {
    text-align: left;
}

._16eme-angle .projets .swiper-pagination-bullet{
    width: 20px !important;
    height: 20px !important;
}
._16eme-angle .projets .swiper-pagination-bullet-active {
    background: #ddbd82 !important;
    outline: 4px solid #fff;
}
._16eme-angle .swiper-pagination-bullet {
    background: #ddbd82 !important;
    opacity: 1;
}
._16eme-angle .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 7px;
}
._16eme-angle .projets .swiper-slide h3{
    opacity: 0.7;
}
._16eme-angle .projets .swiper-slide.swiper-slide-next h3 {
    opacity: 1;
}

@media all and (max-width: 450px) {
    .section-padding.projets h2.title{
        color: #061b31;
    }
    ._16eme-angle .section-padding.maps h2 {
        color: #fff !important;
    }
}
@media all and (max-width: 980px) {
    .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet, .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,.section-padding.atouts span.swiper-pagination-bullet {
        background: #d8c07e !important;
        border: 2px solid #d8c07e;
    }
}