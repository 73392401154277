@import url("https://fonts.googleapis.com/css?family=Abril+Fatface&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

div#onesignal-popover-container #onesignal-popover-dialog .popover-button.secondary {
  color: #977a3c !important;
}

div#onesignal-popover-container #onesignal-popover-dialog .popover-button.primary {
  background: #977a3c !important;
}

div#noprojet.swiper-slider .modal-body .col-md-3:first-child .swiper-slide img.d-block.w-100 {
  position: relative;
  width: 55% !important;
}
.projets .swiper-slide a {
  text-decoration: none;
}
body {
  margin: 0;
  font-family: Montserrat, sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.row.video .col-md-8 .y-hidd {
  overflow: hidden;
}

.row.video .swiper-pagination {
  display: none !important;
}

.section-padding {
  padding: 45px 0;
}

.row.video .col-md-8 iframe {
  width: 100%;
  height: 400px;
}

.plans h2.title,
.maps h2.title,
.atouts h2.title {
  color: #000;
}

.plans .card.bg-light.border-primary {
  border: 0 !important;
  border-radius: 10px !important;
  padding: 15px;
  background-color: initial !important;
}

.plans .card.bg-light.border-primary a {
  margin: 0 auto;
}

.plans .card.bg-light.border-primary a img {
  width: 90%;
  height: 250px;
  margin: 0 auto;
  display: flex;
}

.plans .card.bg-light h2:before {
  counter-increment: section;
  content: "0"counter(section);
  position: absolute;
  margin-top: -75px;
  font-size: 50px;
  color: #000;
  font-family: Abril Fatface !important;
  font-weight: 300 !important;
}

.plans .card.bg-light h2 {
  font-family: "Montserrat";
  font-weight: 600;
  color: #000 !important;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.plans ul {
  list-style-type: none;
  margin: 0;
  font-family: Open Sans;
}

.plans ul li:before {
  content: "-";
  font-size: 18px;
  font-weight: 600;
  left: -10px;
  position: relative;
}

.projets .swiper-container {
  overflow: inherit !important;
}

.projets .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  bottom: -60px !important;
}

.projets .swiper-pagination-bullet-active {
  background: #ffffff !important;
}

.projets .swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
}

.projets .swiper-slide.swiper-slide-next {
  transform: scale(1.1);
  z-index: 999;
}

.projets .swiper-slide img.d-block.w-100 {
  height: 300px;
  border-radius: 20px;
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.18);
}

.projets .swiper-slide {
  text-align: center;
  font-size: 18px;
  padding: 25px;
  /* Center slide text vertically */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transform: scale(0.9);
}

.projets .swiper-container h3 {
  color: #fff;
  font-size: 22px;
  letter-spacing: 0.7px;
  margin-top: 25px;
}

.projets .swiper-button-prev,
.projets .swiper-button-next {
  display: none;
}

.atouts .card {
  padding: 50px;
  background-size: cover;
  border: 0;
  margin-bottom: 30px;
  height: 250px;
  transition: all 0.3s ease-in-out;
}

.atouts .card img {
  width: max-content;
  float: left;
}

.atouts .card .flex-items h3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-size: 24px;
  padding-left: 20px;
}

.atouts .card .flex-items img {
  width: 12%;
}

.atouts .card p {
  color: #fff;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.atouts .flex-items {
  transition: all 0.3s ease-in-out;
}

.atouts .card:hover .flex-items {
  display: flex;
  transition: all 0.3s ease-in-out;
}

.atouts .card:hover p {
  display: block;
  transition: all 0.3s ease-in-out;
}

.contact-popup div#logo-sec {
  display: none;
}

.contact-popup .popup-dropdown {
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  font-size: 13px;
}

.contact-popup .popup-dropdown .p-dropdown-clear-icon {
  display: none;
}

.contact-popup .popup-dropdown .p-dropdown-label {
  padding: 0.375rem 0.75rem !important;
  font-size: 13px;
}

.contact-popup div#form-sec {
  width: 70% !important;
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  top: 5%;
  z-index: 99999;
  padding: 25px;
  border: 3px solid #b7853e;
}

.contact-popup .form-control,
.contact-popup p,
.contact-popup .form-check-label {
  font-size: 13px;
}

.contact-popup #form-sec #btn {
  height: auto;
  padding: 10px 25px;
  font-size: 14px;
  width: auto;
  margin-right: 0;
}

.contact-popup img.d-block {
  width: 134px;
  position: absolute;
  left: 0;
  right: 0;
  margin: -110px auto 0;
}

.contact-popup p.txt {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  width: 50%;
  margin: 0 auto 25px;
}

.contact-popup button.close.contact-section {
  font-size: 45px;
  top: -30px;
  position: relative;
  right: -20px;
  border: 0;
  outline: 0 !important;
}

span.contact-popup-btn {
  color: white !important;
  cursor: pointer;
  background-color: rgb(183, 133, 62);
  padding: 6px 12px;
  border-radius: 5px;
  position: fixed;
  right: -75px;
  transform: rotate(-90deg);
  border: 3px solid #fff;
  top: 40%;
  z-index: 9999999999;
  font-family: montserrat!important;
  font-weight: 600;
}

.swiper-slider .modal-body {
  padding: 0;
}

.swiper-slider .modal-dialog {
  width: 50%;
  max-width: 50%;
}

nav#Header.home-header {
  padding: 35px 30px 10px;
  background-color: #010101;
}

#Header2.home-header {
  padding: 0;
}

.contact-popup.hidden.home-header #Contact {
  border-top: 0;
}

#Header2.home-header img.d-block {
  background-color: initial;
  border-radius: 0;
  margin-left: 120px;
  margin-top: -15px;
  width: 150px;
}

#Header2.home-header span.contact-popup-btn {
  color: white !important;
  background-color: #977a3c;
}

#Header.home-header .link:hover {
  color: #977a3c;
}

.home-page .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
  bottom: -20%;
}

.home-page .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet-active {
  opacity: 1;
  background: #0d0c0b;
  border: 2px solid #b7853e;
}

.home-page .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.home-page .swiper-slide img {
  height: 260px;
  border-radius: 10px;
  box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.17);
  margin-bottom: 20px;
}

.home-page .swiper-slide p {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.mobile-actualites .swiper-slide p,
.actualites .swiper-slide p {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-page .swiper-slide a {
  margin: 0 auto;
  display: flex;
  width: fit-content;
  background-color: #977a3c;
}

.home-page .swiper-container {
  margin-bottom: 40px;
  overflow: initial !important;
}

.home-page #form-sec {
  border-left: 2px solid #c5c7c8;
  border-top: 2px solid #c5c7c8;
}

.home-page #form-sec #btn {
  background-color: #977a3c;
}

.home-page .contact-popup div#form-sec {
  border: 3px solid #977a3c !important;
}

.row.slide-home .col-7 {
  overflow: hidden;
  margin-top: -80px;
}

.row.slide-home .swiper-button-next,
.row.slide-home .swiper-button-prev,
.row.video .swiper-button-prev,
.row.video .swiper-button-next {
  background-image: initial !important;
  top: 111%;
  background-color: #04151f;
  width: 45px;
}

.row.video .swiper-button-prev,
.row.video .swiper-button-next {
  top: 50% !important;
}

.row.slide-home .swiper-button-prev {
  left: 80%;
}

.row.slide-home .swiper-button-next {
  right: 6%;
}

.row.slide-home .swiper-button-next:before,
.row.slide-home .swiper-button-prev:before,
.row.video .swiper-button-next:before,
.row.video .swiper-button-prev:before {
  content: " ";
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(./Images/right-arrow.svg);
  background-size: contain;
  background-repeat: no-repeat;
  transform: scale(0.5);
}

.row.video .carousel-control-next .carousel-control-next-icon,
.row.video .carousel-control-prev .carousel-control-prev-icon {
  background-image: url(./Images/right-arrow.svg);
}

.row.video .carousel-control-next,
.row.video .carousel-control-prev {
  height: 40px;
  width: 45px;
  top: initial;
  bottom: -45px;
  opacity: 1;
}

.row.video .carousel-control-prev {
  transform: rotate(180deg);
  left: 43%;
}

.row.video .carousel-control-next {
  right: 43%;
}

.row.slide-home .swiper-button-prev:before,
.row.video .swiper-button-prev:before {
  transform: scale(0.5) rotate(180deg);
}

.row.slide-home .col-5.txthover {
  background-color: rgba(255, 255, 255, 0.6392156862745098);
  height: 130px;
  width: 100%;
  max-width: 100%;
  top: 36%;
}

.row.slide-home .col-5.txthover img.d-block.w-100 {
  all: initial;
  width: 70% !important;
  margin: 0 auto;
  padding: 20px;
}

.row.slide-home .swiper-slide {
  overflow: hidden;
}

.row.slide-home .col-5.txthover p {
  font-size: 14px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  display: none;
  opacity: 1;
}

.row.slide-home .col-5.txthover a.btn {
  border-radius: 0;
  color: #fff;
  transition: all 0.3s ease-in-out;
  display: none;
  opacity: 1;
}

.row.slide-home .col-5.txthover {
  transition: all 0.3s ease-in-out;
}

.row.slide-home .swiper-slide {
  transition: all 0.3s ease-in-out;
}

.row.slide-home .swiper-slide div:hover .col-5.txthover {
  top: 0;
  height: 100%;
}

.row.slide-home .swiper-pagination-bullets {
  display: none;
}

.row.slide-home .swiper-slide div:hover .col-5.txthover a.btn,
.row.slide-home .swiper-slide div:hover .col-5.txthover p {
  display: block;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

input,
textarea,
select {
  box-shadow: none !important;
}

div#notregroupe-image img {
  width: 540px;
}

div#notregroupe-image {
  z-index: 9;
}

div#notregroupe-image:before,
.section-padding.maps .text-center:before {
  content: "";
  background-image: url(./Images/coquillage.png);
  position: absolute;
  right: -25px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top right;
  top: -80px;
  z-index: -1;
}

div#notregroupe-image:after,
.section-padding.maps .text-center:after {
  content: "";
  background-image: url(./Images/coquillage.png);
  position: absolute;
  left: -25px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom left;
  bottom: -80px;
  z-index: -1;
}

.section-padding.maps .text-center {
  position: relative;
  z-index: 999;
  margin-bottom: 80px !important;
}

button.btn {
  font-family: montserrat!important;
  font-weight: 600;
}

h2.title {
  font-family: "Montserrat";
  font-weight: 600;
}

h1 {
  font-family: "Montserrat";
  font-weight: 600;
}

.swiper-slider .modal-body .carousel-control-prev {
  left: -15%;
}

.swiper-slider .modal-body .carousel-control-next {
  right: -15%;
}

div#noprojet .modal-dialog {
  width: 95%;
  max-width: 95%;
}

div#noprojet.swiper-slider .modal-body {
  padding: 2% 2% 6% 2% !important;
}

div#noprojet.swiper-slider .modal-body .col-md-4 {
  padding: 0 2px !important;
}

div#noprojet.swiper-slider .modal-content {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

div#noprojet.swiper-slider .modal-header {
  color: #977a3c;
  border-bottom: 0;
}

div#noprojet.swiper-slider .modal-header .close {
  margin: -1rem 0rem -1rem auto !important;
  color: #fff !important;
  opacity: 1;
  border: 2px solid !important;
  border-radius: 50% !important;
  padding: 3px 8px !important;
  outline: none !important;
}

div#noprojet .swiper-slide a {
  margin: 0 auto;
  display: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #977a3c;
}

div#noprojet .swiper-slide div:hover a {
  display: flex;
}

div#noprojet.swiper-slider .modal-header h5 {
  font-size: 28px;
  margin-left: 42%;
  font-family: montserrat!important;
  font-weight: 700;
}

.home-page.modal-open .modal-backdrop.show {
  opacity: 0;
}

div#noprojet.swiper-slider .modal-body h4 {
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-family: montserrat!important;
  font-size: 21px;
  border-bottom: 3px solid #977a3c;
  padding-bottom: 10px;
}

.row.slide-home .carousel-control-next-icon {
  background-image: url(./Images/right-arrow.svg);
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.row.slide-home .carousel-control-prev-icon {
  background-image: url(./Images/right-arrow.svg);
  transform: rotate(180deg);
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

div#noprojet.swiper-slider .modal-body .carousel-control-prev {
  left: 0;
  background-color: #977a3c;
  height: 45px;
  width: 45px;
  top: 405px;
  opacity: 1;
}

div#noprojet.swiper-slider .modal-body .carousel-control-next {
  left: 55px;
  background-color: #977a3c;
  height: 45px;
  width: 45px;
  top: 405px;
  opacity: 1;
}
div#noprojet div#carouselNprojets .carousel-control-next {
  left: initial;
  right: 0;
}
div#noprojet div#carouselNprojets .carousel-control-prev {
  left: initial;
  right: 55px;
}
nav#Header.mobile ul.navbar-nav.ml-auto {
  position: absolute;
  top: 56px;
  background-color: rgba(0, 0, 0, 0.75);
  left: -75%;
  padding: 15px 0;
  z-index: 9;
  width: 270px;
}

#Header2.home-header.mobile img.d-block {
  width: 30%;
  margin-top: 15px;
}

nav#Header.mobile ul.navbar-nav.ml-auto .item {
  margin-left: 0;
  padding: 10px 50px;
  font-weight: 600;
}

nav#Header.mobile img.d-block {
  width: 10%;
}

nav#Header.mobile {
  padding: 12px 30px;
}

nav#Header.mobile ul.navbar-nav.ml-auto li.item.dropdown.show a:before {
  content: "";
  background-color: rgba(151, 122, 60, 0.53);
  position: absolute;
  width: 100%;
  height: 39px;
  left: 0;
  top: 3px;
  z-index: -1;
  opacity: 0.4;
}

nav#Header.mobile ul.navbar-nav.ml-auto .dropdown-menu {
  background-color: initial;
  border: 0;
  padding: 0;
  padding-top: 20px;
}

nav#Header.mobile ul.navbar-nav.ml-auto li.item.dropdown.show a {
  color: #fff !important;
  font-weight: 600;
}

nav#Header.mobile ul.navbar-nav.ml-auto .item a.link,
nav#Header.mobile ul.navbar-nav.ml-auto li.item.dropdown.show .dropdown-menu.show a {
  color: #b6b6b6 !important;
  z-index: 9999;
}

nav#Header.mobile ul.navbar-nav.ml-auto li.item.dropdown.show .dropdown-menu.show a.dropdown-item:active {
  color: #ffffff !important;
  background-color: initial;
  border-bottom: 2px solid #977a3c;
}

nav#Header.mobile ul.navbar-nav.ml-auto .dropdown-toggle:after {
  position: absolute;
  right: 15px;
  top: 20px;
}

nav#Header.mobile ul.navbar-nav.ml-auto li.item.dropdown.show .dropdown-toggle:after {
  transform: rotate(-180deg);
}

nav#Header.mobile ul.navbar-nav.ml-auto li.link.social {
  display: flex;
  justify-content: center;
  margin: 15px 0 0;
}

nav#Header.mobile ul.navbar-nav.ml-auto li.link.social a.social-media {
  font-size: 20px;
  color: #d5d5d5;
}

.mobile-hero img.d-block.w-100 {
  height: 355px;
}

.mobile-slide .row.slide-home .col-5.txthover p,
.mobile-slide .row.slide-home .col-5.txthover a.btn {
  display: block;
}

.mobile-slide .swiper-slide {
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px;
}

.mobile-slide .row.slide-home .col-5.txthover img.d-block.w-100 {
  padding: 0;
  width: 100% !important;
  border-radius: 6px;
  margin-bottom: 10px;
}

.mobile-slide .row.slide-home .col-5.txthover {
  padding: 5px;
}

.mobile-slide .swiper-slide h3 {
  color: #000;
  font-size: 19px;
  margin-left: 23px;
}

.mobile-slide .row.slide-home .col-5.txthover p {
  text-align: left;
  color: #898989;
}

.mobile-slide .row.slide-home .col-5.txthover a.btn {
  color: #977a3c!important;
  background-color: initial;
  float: right;
  font-weight: 600;
}

.mobile-slide .row.slide-home .swiper-pagination-bullets {
  display: block;
  bottom: -8% !important;
  padding-left: 20%;
}

.mobile-slide .row.slide-home .swiper-button-next,
.mobile-slide .row.slide-home .swiper-button-prev {
  display: none;
}

.mobile-slide .row.slide-home .swiper-pagination-bullets:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 150px;
  background-color: #000;
  bottom: -15px;
  right: -20%;
  border-radius: 10px;
  z-index: -30;
}

.mobile-actualites .swiper-pagination-bullets:after {
  content: "";
  position: absolute;
  width: 116%;
  height: 300px;
  background-color: #000;
  bottom: -25px;
  right: -8%;
  border-radius: 10px;
  z-index: -30;
}

.mobile-slide .row.slide-home .swiper-slide {
  background-color: #fff;
}

.mobile-slide .swiper-container-android .swiper-slide,
.mobile-slide .swiper-wrapper {
  background-color: #fff;
  z-index: 999 !important;
  border-radius: 5px;
}

.mobile-actualites .swiper-container-android .swiper-slide,
.mobile-actualites .swiper-wrapper {
  z-index: 999 !important;
  border-radius: 5px;
}

.mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet,
.mobile-actualites .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
  background: #b7853e !important;
  border: 2px solid #b7853e;
  opacity: 1;
}

.mobile-actualites .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
  left: -20%;
  position: relative;
}

.mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet-active,
.mobile-actualites .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet-active {
  width: 70px !important;
  border-radius: 10px !important;
}

.mobile-engagements div#engagements-section {
  background-color: initial;
  box-shadow: initial;
  margin-top: -35%;
}

.mobile-engagements div#engagements-section .col-6 {
  background-color: whitesmoke;
  box-shadow: 0 10px 9px 0px rgba(0, 0, 0, 0.23);
  margin: 8px;
  flex: 0 0 44%;
  max-width: 44%;
  padding: 17px 10px 0;
}

.mobile-engagements div#engagements-section .col-6 img.d-block.w-100 {
  width: 40% !important;
}

.mobile-engagements div#engagements-section .col-6 .title {
  margin-top: 0 !important;
}

.mobile-engagements div#engagements-section .col-6 h3 {
  font-size: 15px;
  text-align: center;
  justify-content: center;
  margin: 8px auto 0;
  border-bottom: 0;
}

.mobile-engagements div#engagements-section .col-6 img.d-block.arrow {
  width: 18%;
  filter: invert(1);
  float: right;
  top: -6px;
  right: 3px;
  position: relative;
}

.mobile-actualites h1 {
  color: rgb(183, 133, 62) !important;
  border: 0;
  padding-top: 0;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin-bottom: 56px !important;
}

.mobile-actualites .home-page {
  padding: 45px 10px 80px !;
}

.mobile-actualites .swiper-slide p {
  color: #fff;
  text-align: left;
}

.mobile-actualites .swiper-slide img {
  border-radius: 4px;
}

.mobile-actualites a.btn {
  background-color: initial;
  text-align: right;
  float: right;
  font-weight: 600;
  color: #b7853e;
}

.fullsize {
  position: absolute;
  bottom: 2%;
  right: 7%;
  z-index: 99;
}

.fullsize img {
  transform: scale(0.7);
  width: auto !important;
  height: auto !important;
}

.plans.section-padding .row .col-4 {
  overflow: hidden;
}

.plans .carousel-item.active a:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.34);
  position: absolute;
  top: 0;
  left: 5%;
  right: 0;
  z-index: 999999;
  width: 90%;
  height: 100%;
}

.Galerie .carousel-control-prev-icon,
.Galerie .carousel-control-next-icon {
  filter: invert(1);
}

.Galerie a.carousel-control-prev,
.Galerie a.carousel-control-next {
  opacity: 1;
}

.apropos .btn a {
  color: #fff;
  text-decoration: none;
}

.plans div#carouselPlansControls .carousel-item {
  max-height: 100% !important;
}

.projets .swiper-slide {
  text-decoration: none;
}

.the-sand-house #logo-sec img#image,
.ricoflor-palmes #logo-sec img#image,
.magic-house #logo-sec img#image,
.garden-bay #logo-sec img#image,
.blue-waters-house #logo-sec img#image,
.les-perles-de-bentriaa #logo-sec img#image {
  display: none;
}

#logo-sec img#backgroundtsh,
#logo-sec img#backgroundrp,
#logo-sec img#backgroundmh,
#logo-sec img#backgroundgb,
#logo-sec img#backgroundbwh,
#logo-sec img#backgroundlpdb {
  display: none;
}

.the-sand-house #logo-sec img#backgroundtsh,
.ricoflor-palmes #logo-sec img#backgroundrp,
.magic-house #logo-sec img#backgroundmh,
.garden-bay #logo-sec img#backgroundgb,
.blue-waters-house #logo-sec img#backgroundbwh,
.les-perles-de-bentriaa #logo-sec img#backgroundlpdb {
  display: block;
  height: 786px;
  width: 100%;
}

.the-sand-house #logo-sec,
.ricoflor-palmes #logo-sec,
.magic-house #logo-sec,
.garden-bay #logo-sec,
.blue-waters-house #logo-sec,
.les-perles-de-bentriaa #logo-sec {
  padding: 0;
  margin: 0;
}

#Contact #form-sec.col-md-7 {
  height: 786px;
}

.magic-house-beach #Contact {
  background-image: url(./Images/footer-mhb.jpg) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}
._16eme-angle #Contact {
  background-image: url(./Images/footer-16-angle.jpg) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}
.anfabloom #Contact {
  background-image: url(./Images/footer-anfabloom.jpg) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}
.the-sand-house #Contact {
  background-image: url(./Images/footer-tsh.jpg) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.ricoflor-palmes #Contact {
  background-image: url(./Images/footer-rp.png) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.magic-house #Contact {
  background-image: url(./Images/footer-mh2.jpeg) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.garden-bay #Contact {
  background-image: url(./Images/footer-gb.jpg) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

/* .blue-waters-house #Contact{
  background-image: url(./Images/footer-bwh.png)!important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
} */
.apropos #Contact,
.merci #Contact,
.home-page #Contact {
  background-image: url(./Images/footer-lpp.png) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.blue-waters-house #Contact {
  background-image: url(./Images/footer-bwh.png) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.home-page #Contact {
  background-image: url(./Images/footer-home.jpeg) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.les-perles-de-bentriaa #Contact {
  background-image: url(./Images/footer-lpb.png) !important;
  background-size: cover;
  background-position: center left;
  background-repeat: no-repeat;
}

.plans button.close,
.Galerie button.close {
  z-index: 9999999999;
  font-size: 38px;
  color: #fff;
  cursor: pointer !important;
  width: max-content;
  float: right;
  position: absolute;
  right: -40px;
  top: 0px;
  opacity: 1;
}

.actuality nav#Header2 img {
  display: none !important;
}

.actuality .home-page h1 {
  border: 0;
  font-weight: 600;
  font-size: 38px;
  letter-spacing: 2px;
}

.actuality div#Contact {
  display: none;
}

.actuality .article.col-md-6 {
  padding: 0 55px;
  margin: 30px 0;
}

.actuality .article.col-md-6 img {
  border-radius: 6px;
  height: 350px;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.42);
  margin-bottom: 25px;
}

.actuality .article.col-md-6 a.btn {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: fit-content;
  padding: 10px 35px;
}

.actuality .article.col-md-6 p {
  font-weight: 600;
  font-size: 20px;
}

.actuality .article.col-md-10 {
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.actuality .article.col-md-10 p.title {
  font-size: 30px;
  color: rgb(183, 133, 62);
  padding: 0;
  text-transform: none;
  margin-top: 30px;
  font-weight: 600;
}

.actuality .article.col-md-10 p.date,
.actuality .article.col-md-10 p.content {
  color: rgb(155, 155, 155);
}

.actuality .article.col-md-10 p.content {
  font-size: 18px;
  font-weight: 500;
}

.actuality .contact-popup div#Contact {
  display: block;
}

.plans.section-padding .carousel-item {
  max-height: initial !important;
}

img.signature {
  float: right;
  height: auto;
  width: 30%;
}

.Galerie a.carousel-control-next {
  right: 2% !important;
}

.Galerie a.carousel-control-prev {
  left: 2% !important;
}

.Galerie .modal-body span.carousel-control-next-icon,
.Galerie .modal-body span.carousel-control-prev-icon {
  width: 23px !important;
  height: 23px !important;
  color: #fff !important;
  filter: invert(0) !important;
}

.Galerie a.carousel-control-prev,
.Galerie a.carousel-control-next {
  height: fit-content;
  top: 45%;
  background-color: rgb(44 44 44 / 80%);
  padding: 10px;
  width: fit-content;
}

.Galerie .modal-body .carousel-control-next-icon,
.Galerie .modal-body .carousel-control-prev-icon {
  width: 50px !important;
  height: 50px !important;
}

.modal-open .modal {
  top: 0 !important;
  height: 100vh !important;
}

.Galerie .modal.show .modal-dialog {
  margin-top: 85px !important;
  width: 100% !important;
}

.modal.show .modal-dialog {
  max-width: 100% !important;
}

.modal-open .modal {
  padding-right: 0 !important;
}

.Galerie .swiper-slider .modal-body {
  /* max-width: 50% !important; */
  margin: 0 auto !important;
}

.Galerie .modal-content {
  max-width: 50% !important;
  margin: 0 auto;
}

.modal-content {
  background-color: initial !important;
  border: 0 !important;
}

button.close span {
  font-weight: 100;
  text-shadow: none;
}

button.close {
  text-shadow: none;
}

.plans button.close,
.Galerie button.close {
  right: 0;
  top: 0;
  opacity: 1;
  width: 4.3%;
  background-color: initial;
  padding: 2px 0 4px;
  text-align: right;
  padding-right: 15px;
}

@media all and (max-width: 450px) {
  .home-page #Header2.home-header img.d-block,
  .contact #Header2.home-header img.d-block,
  body.actuality #Header2.home-header img.d-block {
    margin-left: 5px;
  }

  .home-page ul.navbar-nav.ml-auto,
  .contact ul.navbar-nav.ml-auto,
  body.actuality ul.navbar-nav.ml-auto {
    left: initial;
    right: -75%;
    top: 54px !important;
  }

  .home-page nav#Header.mobile img.d-block,
  .contact nav#Header.mobile img.d-block,
  body.actuality nav#Header.mobile img.d-block {
    margin-left: 94%;
  }

  .modal-open .modal {
    top: 0 !important;
    height: 100vh;
  }

  .Galerie .modal.show .modal-dialog {
    margin-top: 90px !important;
    width: 100% !important;
  }

  .plans button.close,
  .Galerie button.close {
    right: 0;
    top: 0;
    opacity: 1;
    width: 16%;
    background-color: initial;
    padding: 2px 0 4px;
    text-align: right;
    padding-right: 15px;
  }

  .plans button.close {
    width: 19.5% !important;
  }

  .Galerie .swiper-slider .modal-body {
    max-width: 85% !important;
  }

  .swiper-slider .modal-body .carousel-control-prev {
    left: 0 !important;
    z-index: 9999 !important;
    opacity: 1 !important;
    top: 112%;
  }

  .swiper-slider .modal-body .carousel-control-next {
    right: 0 !important;
    z-index: 9999 !important;
    opacity: 1 !important;
    top: 112%;
  }

  .Galerie .swiper-slider .modal-body .carousel-control-prev {
    left: 2% !important;
    z-index: 9999 !important;
    opacity: 1 !important;
    top: 40% !important;
  }

  .Galerie .swiper-slider .modal-body .carousel-control-next {
    right: 2% !important;
    z-index: 9999 !important;
    opacity: 1 !important;
    top: 40% !important;
  }

  .swiper-slide.swiper-slide-next {
    z-index: 0 !important;
  }

  .Galerie .modal-body .carousel-control-next-icon,
  .Galerie .modal-body .carousel-control-prev-icon {
    width: 20px !important;
    height: 20px !important;
  }
}

@media all and (max-width: 980px) {
  .Galerie .modal-content {
    max-width: 100% !important;
}
  div#form-sec.col-md-7 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row.video .carousel-control-next {
    right: 38%;
    bottom: -135px;
  }

  .row.video .carousel-control-prev {
    left: 38%;
    bottom: -135px;
  }

  img.signature {
    float: right;
    width: 100%;
  }

  .home-page.apropos div#motpresident-image img {
    display: none;
  }

  .home-page.apropos h1 {
    margin-bottom: 25px !important;
  }

  div#notregroupe-image img {
    width: 100%;
    margin: 25px 0;
  }

  .row.video iframe {
    width: 100%;
  }

  h1 {
    font-size: 18px !important;
    margin: initial !important;
    border: 0 !important;
    padding-top: 0 !important;
    font-weight: 700 !important;
    letter-spacing: 0.8px !important;
  }

  div#mobilecontact-section input,
  div#mobilecontact-section select,
  div#mobilecontact-section textarea {
    border-top: 0;
    border-radius: 0 !important;
    border-right: 0;
    border-left: 0;
    border-color: #7a797d !important;
    background-color: initial;
  }

  div#mobilecontact-section div#form-sec {
    border: 0;
    background-color: #f8f8f8;
  }

  div#mobilecontact-section div#form-sec h1 {
    margin-bottom: 25px !important;
  }

  div#mobilecontact-section img {
    width: 40%;
    margin: 20px auto;
    display: flex;
  }

  div#mobilecontact-section div#infocontact-text h5 {
    font-family: montserrat!important;
    font-weight: 600;
    font-size: 24px;
  }

  div#mobilecontact-section div#infocontact-text p,
  div#mobilecontact-section div#infocontact-text li,
  div#mobilecontact-section div#infocontact-text li a {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    font-family: open sans;
    text-decoration: none;
  }

  div#mobilecontact-section div#infocontact-text ul svg {
    position: absolute;
    left: 25px;
    font-size: 20px;
  }

  div#mobilecontact-section div#infocontact-text svg {
    font-size: 20px;
    color: #b7853e;
  }

  div#mobilecontact-section div#infocontact-text ul li {
    margin: 8px 0;
    padding-left: 40px;
    overflow-wrap: break-word;
  }

  div#mobilecontact-section div#infocontact-section #infocontact-text ul {
    left: -12%;
    position: relative;
  }

  div#mobilecontact-section div#infocontact-section #infocontact-text {
    padding-top: 6%;
  }

  div#infocontact-text {
    padding: 30px;
  }

  .footer {
    display: none;
  }

  div#maps_iframe {
    display: none;
  }

  .contact-popup div#form-sec {
    width: 100% !important;
    border: 0 !important;
    z-index: 9999999999;
    top: 0;
  }

  .contact-popup p.txt {
    font-size: 12px;
    width: 100%;
    margin: 10px auto;
  }

  .contact-popup img.d-block {
    width: 155px;
    position: relative;
    margin-top: 0;
  }

  .contact-popup div#form-sec {
    height: 100%;
    overflow: auto;
  }

  .contact-popup div#form-sec .col-md-6.col-12 {
    margin-top: 12px;
  }

  .contact nav#Header2 ul.navbar-nav.ml-auto {
    display: none;
  }

  .contact div#maps_iframe {
    display: block;
  }

  .contact div#mobilecontact-section div#form-sec {
    padding-top: 85px;
  }

  body.merci nav#Header2.navbar img.d-block {
    display: block !important;
  }

  body.merci .row.slide-home:first-child .col-12:first-child {
    display: none;
  }

  body.merci .mobile-slide div.first {
    padding: 30px 30px 15% !important;
  }

  body.merci .remerciements h4 {
    font-size: 15px;
    text-align: center;
  }

  body.merci .remerciements h2.title {
    font-size: 20px;
  }

  body.merci .section-padding.remerciements {
    padding: 75px 50px 0;
  }

  .scroll-to-top {
    position: fixed;
    left: 20px;
    bottom: -5px;
    z-index: 9999;
  }

  .carriere-form,
  .carriere-form .infos ul {
    padding: 0 !important;
  }

  .row.carriere-form .form {
    padding: 20px 65px 65px !important;
  }

  .row.carriere-form label.title-label {
    font-size: 12px !important;
    height: 35px;
    position: relative;
    display: flex;
  }

  .progress.mx-auto {
    position: absolute;
    margin-top: -68% !important;
    transform: scale(0.4) !important;
    left: 10% !important;
  }

  .carriere div#mobilecontact-section #contact-section-mobile,
  .carriere div#mobilecontact-section #infocontact-text {
    display: none !important;
  }

  .carriere nav#Header2 img.d-block {
    left: 0 !important;
    right: 0 !important;
  }

  .carriere div#carriere-section {
    padding: 27% 15% 0 !important;
  }

  .carriere #Contact,
  .carriere span.contact-popup-btn {
    display: block !important;
  }

  .carriere-form .infos h1 {
    width: fit-content !important;
    border-bottom: 3px solid #977a3a !important;
    margin: 30px auto !important;
    padding-bottom: 5px;
  }

  .home-page.apropos nav#Header2.navbar img {
    display: block !important;
  }

  .home-page.apropos #notregroupe-section {
    padding: 105px 50px 50px !important;
  }

  .home-page.apropos div#notregroupe-image img {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .home-page.apropos div#motpresident-image img {
    width: 60%;
    margin: 20px;
  }

  .home-page.apropos div#vision-image {
    padding: 0 !important;
  }

  .home-page.apropos div#vision-image img {
    width: 100% !important;
  }

  .home-page.apropos #vision-section {
    margin-bottom: 185px !important;
  }

  .home-page.apropos .mobile-engagements hr {
    position: relative !important;
    top: -235px !important;
  }

  .home-page.apropos div#notregroupe-image {
    padding: 0 !important;
  }

  .actuality nav#Header2 img {
    display: block !important;
  }

  .actuality-titre {
    color: rgb(183, 133, 62);
    margin-bottom: 25px !important;
    margin-left: 14px !important;
  }

  .mobile-desc {
    margin: -60px 0 0;
  }

  .section-padding.desc {
    border-top-right-radius: 45px;
    border-top-left-radius: 45px;
    margin-top: -35px;
  }

  div#notregroupe-image:before,
  .section-padding.maps .text-center:before {
    top: -65px !important;
    z-index: -1 !important;
    transform: scale(0.7) !important;
    right: -85px !important;
  }

  div#notregroupe-image:after,
  .section-padding.maps .text-center:after {
    bottom: -75px !important;
    z-index: -1 !important;
    transform: scale(0.7) !important;
    left: -95px !important;
  }

  #Header2 {
    padding: 0 !important;
  }

  #Header2 img.d-block {
    width: 30% !important;
    margin-top: 35px !important;
  }

  nav#Header.home-header.lp {
    background-color: #fff;
  }

  nav#Header.mobile ul.navbar-nav.ml-auto.lp {
    padding: 65px 0 15px !important;
  }

  .Galerie button.btn {
    padding: 7px 5px !important;
    margin-left: 15px !important;
  }

  .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    display: block !important;
    top: 40px;
    position: relative;
  }

  .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,
  .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet {
    background: #00adee !important;
    border: 2px solid #00adee;
    opacity: 1;
    height: 15px;
    width: 15px;
  }

  .Galerie span.swiper-pagination-bullet.swiper-pagination-bullet-active,
  .plans span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 70px !important;
    border-radius: 10px !important;
  }

  .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    text-align: left;
    position: relative;
    top: 15px;
  }

  .Galerie .swiper-slide.swiper-slide-next {
    transform: scale(1) !important;
  }

  .Galerie .swiper-slide.swiper-slide-active {
    transform: scale(1.1);
  }

  .Galerie .swiper-container {
    margin-left: 25px;
  }

  .swiper-slider .modal-dialog {
    width: 75%;
    max-width: 100%;
    margin: 50px auto;
  }

  h2.title {
    font-size: 18px !important;
    margin: initial !important;
    border: 0 !important;
    padding-top: 0 !important;
    font-weight: 700 !important;
    letter-spacing: 0.8px !important;
  }

  h2.title:before,
  .the-sand-house .section-padding.Galerie h2.title:before,
  .the-sand-house .video .section.section-padding h2.title:before,
  .the-sand-house .virtu .section.section-padding h2.title:before,
  .the-sand-house .section-padding.projets h2.title:before {
    margin-top: 9px !important;
  }

  .section-padding.maps iframe {
    width: 100% !important;
    height: 300px;
  }

  .section-padding.maps {
    padding: 0 !important;
  }

  .the-sand-house div.section-padding.projets {
    background-color: #fff !important;
    padding: 0 !important;
  }

  .the-sand-house div.section-padding.projets h2.title {
    color: #000;
  }

  .the-sand-house .mobile-slide .row.slide-home .swiper-pagination-bullets:after {
    background-color: rgb(131, 131, 130) !important;
    height: 400px !important;
  }

  .the-sand-house .mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
    background: rgb(216, 192, 126) !important;
    border: 2px solid rgb(216, 192, 126) !important;
  }

  .none {
    display: none !important;
  }

  .ricoflor-palmes #Header2 img.d-block,
  .les-perles-de-bentriaa #Header2 img.d-block,
  .magic-house #Header2 img.d-block,
  .blue-waters-house #Header2 img.d-block,
  .garden-bay #Header2 img.d-block,
  .the-sand-house #Header2 img.d-block {
    width: 42% !important;
    padding: 5px !important;
    margin-top: 85px !important;
  }

  .ricoflor-palmes .mobile-slide .first,
  .les-perles-de-bentriaa .mobile-slide .first,
  .magic-house .mobile-slide .first,
  .blue-waters-house .mobile-slide .first,
  .garden-bay .mobile-slide .first,
  .the-sand-house .mobile-slide .first {
    padding: 30px 30px 120px !important;
  }

  .contact div#infocontact-text img {
    all: initial !important;
    width: 40% !important;
    margin: 20px auto !important;
    display: flex !important;
  }

  .home-page .mobile-engagements div#engagements-section .row {
    padding: 0 30px 30px;
    margin-top: -65px;
  }

  .home-page #engagements-section h1 {
    top: -70px;
    position: relative;
  }

  .mob_plans .swiper-container {
    z-index: 1050;
  }

  .home-page .mobile-engagements {
    margin-top: 70px;
  }

  .home-page.apropos .mobile-engagements div#engagements-section .row {
    margin-top: 0;
  }

  .atouts .card {
    padding: 23px;
    margin-bottom: 20%;
    height: 210px;
  }

  .atouts .flex-items {
    display: flex !important;
  }

  .atouts .card .flex-items img {
    width: auto;
    height: 45px;
  }

  .atouts .card .flex-items h3 {
    color: #000;
    font-size: 15px;
    padding-left: 15px;
  }

  .atouts .card p {
    color: #000;
    font-size: 13px;
    display: block !important;
  }

  .section-padding.atouts {
    background-color: rgb(255, 255, 255) !important;
  }

  .the-sand-house .atouts h2.title {
    color: #000 !important;
  }

  .section-padding.atouts:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background-color: rgb(131, 131, 131);
    width: 100%;
    height: 25.5%;
    margin-top: 48%;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .the-sand-house .section-padding.atouts:before {
    height: 45.5%;
  }

  .section-padding.atouts,
  .section-padding.Galerie {
    padding: 0 !important;
  }

  .section-padding.atouts span.swiper-pagination-bullet {
    background: #d8c07e !important;
    border: 2px solid #d8c07e;
    opacity: 1;
    height: 15px;
    width: 15px;
  }

  .section-padding.atouts span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 70px !important;
    border-radius: 10px !important;
  }

  .section-padding.atouts .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    text-align: left;
    padding-left: 5%;
  }

  .plans .card.bg-light.border-primary {
    padding: 15px 0 !important;
  }

  .ricoflor-palmes div.section-padding.Galerie,
  .les-perles-de-bentriaa div.section-padding.Galerie,
  .magic-house div.section-padding.Galerie,
  .blue-waters-house div.section-padding.Galerie,
  .garden-bay div.section-padding.Galerie,
  .ricoflor-palmes div.section-padding.projets,
  .les-perles-de-bentriaa div.section-padding.projets,
  .magic-house div.section-padding.projets,
  .blue-waters-house div.section-padding.projets,
  .garden-bay div.section-padding.projets {
    background-color: #fff !important;
  }

  .ricoflor-palmes div.section-padding.projets,
  .les-perles-de-bentriaa div.section-padding.projets,
  .magic-house div.section-padding.projets,
  .blue-waters-house div.section-padding.projets,
  .garden-bay div.section-padding.projets {
    padding: 0 !important;
  }

  .ricoflor-palmes .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,
  .ricoflor-palmes .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,
  .ricoflor-palmes .mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
    background: #8dc774 !important;
    border: 2px solid #8dc774;
  }

  .les-perles-de-bentriaa .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet {
    background: #358fa7 !important;
    border: 2px solid #358fa7;
  }

  .magic-house .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet {
    background: #73c7dc !important;
    border: 2px solid #73c7dc;
  }

  .blue-waters-house .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet {
    background: #00adee !important;
    border: 2px solid #00adee;
  }

  .blue-waters-house .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,
  .garden-bay .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,
  .magic-house .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,
  .les-perles-de-bentriaa .plans .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet,
  .blue-waters-house .mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet,
  .garden-bay .mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet,
  .magic-house .mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet,
  .les-perles-de-bentriaa .mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
    background: #fff !important;
    border: 2px solid #fff;
  }

  .garden-bay .Galerie .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets span.swiper-pagination-bullet {
    background: #16c1f3 !important;
    border: 2px solid #16c1f3;
  }

  img.d-block.menu.m1,
  img.d-block.menu.m2,
  img.d-block.menu.m3,
  img.d-block.menu.m5,
  img.d-block.menu.m6,
  img.d-block.menu.m7,
  img.d-block.menu.m4 {
    display: none !important;
  }

  .ricoflor-palmes img.d-block.menu.m5 {
    display: block !important;
  }

  .les-perles-de-bentriaa img.d-block.menu.m3 {
    display: block !important;
  }

  .the-sand-house img.d-block.menu.m1 {
    display: block !important;
  }

  .garden-bay img.d-block.menu.m6 {
    display: block !important;
  }

  .blue-waters-house img.d-block.menu.m4 {
    display: block !important;
  }

  .magic-house img.d-block.menu.m2 {
    display: block !important;
  }
  ._16eme-angle img.d-block.menu.m7 {
    display: block !important;
  }
  .anfabloom img.d-block.menu.m7 {
    display: block !important;
  }
  .magic-house-beach img.d-block.menu.m1 {
    display: block !important;
  }

  .ricoflor-palmes .mobile-slide .row.slide-home .swiper-pagination-bullets:after {
    background-color: #c97953 !important;
    height: 400px;
  }

  .garden-bay .mobile-slide .row.slide-home .swiper-pagination-bullets:after {
    background-color: #16c1f3 !important;
    height: 400px;
  }

  .blue-waters-house .mobile-slide .row.slide-home .swiper-pagination-bullets:after {
    background-color: #206392 !important;
    height: 400px;
  }

  .magic-house .mobile-slide .row.slide-home .swiper-pagination-bullets:after {
    background-color: #73c7dc !important;
    height: 400px;
  }

  .les-perles-de-bentriaa .mobile-slide .row.slide-home .swiper-pagination-bullets:after {
    background-color: #358fa7 !important;
    height: 400px;
  }

  .ricoflor-palmes .Galerie h2.title,
  .ricoflor-palmes .plans h2.title,
  .ricoflor-palmes .maps h2.title,
  .ricoflor-palmes .section-padding.projets h2.title {
    color: #c97953;
  }

  .garden-bay .Galerie h2.title,
  .garden-bay .plans h2.title,
  .garden-bay .maps h2.title,
  .garden-bay .section-padding.projets h2.title {
    color: #16c1f3;
  }

  .blue-waters-house .Galerie h2.title,
  .blue-waters-house .plans h2.title,
  .blue-waters-house .maps h2.title,
  .blue-waters-house .section-padding.projets h2.title {
    color: #000;
  }

  .magic-house .Galerie h2.title,
  .magic-house .plans h2.title,
  .magic-house .maps h2.title,
  .magic-house .section-padding.projets h2.title {
    color: #286ea9;
  }

  .les-perles-de-bentriaa .Galerie h2.title,
  .les-perles-de-bentriaa .plans h2.title,
  .les-perles-de-bentriaa .maps h2.title,
  .les-perles-de-bentriaa .section-padding.projets h2.title {
    color: #000;
  }

  .ricoflor-palmes .plans.section-padding:before,
  .garden-bay .plans.section-padding:before,
  .blue-waters-house .plans.section-padding:before,
  .magic-house .plans.section-padding:before,
  .les-perles-de-bentriaa .plans.section-padding:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 490px;
    background-color: #c97953;
    left: 0;
    margin-top: 55%;
    border-radius: 15px;
  }

  .les-perles-de-bentriaa .plans.section-padding:before {
    background-color: #358fa7 !important;
  }

  .magic-house .plans.section-padding:before {
    background-color: #73c7dc !important;
  }

  .garden-bay .plans.section-padding:before {
    background-color: #16c1f3 !important;
  }

  .blue-waters-house .plans.section-padding:before {
    background-color: rgb(32, 99, 146) !important;
  }

  .ricoflor-palmes .plans .card.bg-light h2:before,
  .les-perles-de-bentriaa .plans .card.bg-light h2:before,
  .magic-house .plans .card.bg-light h2:before,
  .garden-bay .plans .card.bg-light h2:before,
  .blue-waters-house .plans .card.bg-light h2:before,
  .ricoflor-palmes .plans .card.bg-light h2,
  .les-perles-de-bentriaa .plans .card.bg-light h2,
  .magic-house .plans .card.bg-light h2,
  .garden-bay .plans .card.bg-light h2,
  .blue-waters-house .plans .card.bg-light h2,
  .ricoflor-palmes .plans ul,
  .les-perles-de-bentriaa .plans ul,
  .magic-house .plans ul,
  .garden-bay .plans ul,
  .blue-waters-house .plans ul {
    color: #fff !important;
  }

  .blue-waters-house .section-padding.projets h2.title:before {
    border: 2px solid rgb(32, 99, 146) !important;
  }

  .les-perles-de-bentriaa .section-padding.Galerie h2.title:before,
  .les-perles-de-bentriaa .section-padding.projets h2.title:before {
    border: 2px solid rgb(53, 143, 167) !important;
  }

  .mobile-slide .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
  }

  .magic-house .section-padding.Galerie button.btn {
    background-color: #2a6ea7 !important;
    color: #fff !important;
  }

  .blue-waters-house .section-padding.Galerie button.btn {
    background-color: #00adee !important;
    color: #fff !important;
  }

  .garden-bay div.section-padding.Galerie button.btn {
    background-color: #16c1f3 !important;
    color: #fff !important;
  }

  .les-perles-de-bentriaa .section-padding.Galerie button.btn {
    background-color: #358fa7 !important;
    color: #fff !important;
  }

  .infocontactlp .gotomap {
    position: absolute;
    right: 18%;
    transform: rotate(90deg);
    margin-top: -25%;
  }

  .backinfos svg {
    transform: rotate(-90deg);
    margin-top: 5%;
  }

  .mapcontact {
    display: none;
  }

  .the-sand-house .infocontactlp .gotomap,
  .ricoflor-palmes .infocontactlp .gotomap,
  .magic-house .infocontactlp .gotomap,
  .blue-waters-house .infocontactlp .gotomap,
  .garden-bay .infocontactlp .gotomap,
  .les-perles-de-bentriaa .infocontactlp .gotomap {
    display: block;
  }

  .mapcontact iframe {
    margin-top: 5%;
  }

  .row.slide-home .swiper-slide {
    height: 400px !important;
  }

  .row.slide-home .col-5.txthover {
    top: 0 !important;
  }

  .mobile-slide .row.slide-home .col-5.txthover img.d-block.w-100 {
    height: 200px !important;
  }

  .ricoflor-palmes .mobile-slide .btn {
    color: #c97953 !important;
  }

  .magic-house .mobile-slide .btn {
    color: #2a6ea7 !important;
  }

  .les-perles-de-bentriaa .mobile-slide .btn {
    color: #358fa7 !important;
  }

  .blue-waters-house .mobile-slide .btn {
    color: #00adee !important;
  }

  .garden-bay .mobile-slide .btn {
    color: #85c443 !important;
  }

  .Galerie button.btn {
    font-size: 13px !important;
  }

  .row.video .col-md-8 iframe {
    height: 250px !important;
    margin-top: 25px;
  }

  .Galerie .swiper-slide img.d-block.w-100 {
    height: 200px !important;
  }

  .progress.mx-auto {
    display: none !important;
  }

  .carriere-form label.cv,
  .carriere-form label.lettre {
    width: 100% !important;
  }

  .home-page.apropos #engagements-section h1 {
    top: -25px !important;
  }

  .mobile-actualites .swiper-slide img {
    height: 160px !important;
  }

  div#mobilecontact-section div#infocontact-text .col-3 ul svg {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  div#mobilecontact-section div#infocontact-text .col-3 ul li {
    text-align: center !important;
    padding: 0 !important;
  }

  div#mobilecontact-section div#infocontact-text .col-3 ul li a {
    margin: 0 !important;
  }

  .mobile-slide .row.slide-home .col-5.txthover p {
    width: 100% !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    height: 65px !important;
  }

  .home-page #infocontact-text {
    background-image: linear-gradient(rgba(255, 255, 255, 0.88),
        rgba(255, 255, 255, 0.88)),
      url(./Images/footer-home.jpeg) !important;
  }

  body.home-page .mobile-slide .row.slide-home.none,
  body.home-page .mobile-slide .row.slide-home h3.none {
    display: block !important;
  }

  body.home-page .mobile-slide .first {
    border-top-right-radius: 45px;
    border-top-left-radius: 45px;
  }

  body.home-page .mobile-slide {
    border-top-right-radius: 45px;
    border-top-left-radius: 45px;
    margin-top: -30px;
    z-index: 99999999999 !important;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  div#notregroupe-image:before {
    top: -25px !important;
  }

  div#onesignal-bell-container {
    bottom: -14px !important;
    position: relative;
  }
}
@media all and (max-width: 1270px) {
  nav#Header.home-header li.item a {
    font-size: 0.8rem!important;
  }
  .item {
    margin-left: 22px!important;
  }
}
@media all and (max-width: 1110px) {
  nav#Header.home-header li.item a {
    font-size: 0.7rem!important;
  }
  nav#Header.home-header .info {
    font-size: 0.8rem !important;
}
#Header2.home-header img.d-block {
  width: 115px!important;
}
}
.modal-content .row.slide-home .swiper-container {
  overflow: hidden !important;
}
.modal-content .row.slide-home .swiper-container {
  overflow: hidden !important;
  min-height: 450px!important;
}
.modal-content .row.slide-home .swiper-button-next,
.modal-content .row.slide-home .swiper-button-prev
{
  top: 95%!important;
  background-color: #977a3c!important;
  height: 45px!important;
  width: 45px!important;
}
.modal-content .row.slide-home .swiper-button-next {
  left: 8%!important;
}
.modal-content .row.slide-home .swiper-button-prev {
  left: 0!important;
}

.modal-content .row.slide-home .swiper-button-next:before{
  transform: scale(0.8)!important;
}
.modal-content .row.slide-home .swiper-button-prev:before{
  transform: scale(0.8) rotate(180deg)!important;
}
.p-dropdown-panel.p-component {
  z-index: 9999999 !important;
}