.blue-waters-house #Header {
    padding: 12px 30px;
    background-color: #206392;
}
.blue-waters-house #Header .info,.blue-waters-house #Header .info a {
    color: #ffffff;
    text-decoration: none;
}
.blue-waters-house #Header2 li.item a:hover {
    color: #206392;
    transition: all 0.3s ease-in-out;
}
.blue-waters-house #Contact {
    /* border-top: 2px solid rgb(32, 99, 146); */
    border-top: 0;
}
.blue-waters-house span.contact-popup-btn {
    color: white!important;
    background-color: rgb(0, 173, 238);
}
.blue-waters-house .contact-popup div#form-sec {
    border: 3px solid #206392;
}
.blue-waters-house #btn {
    background-color: #00adee;
}
.blue-waters-house h1 {
    color: rgb(32, 99, 146);
}
.blue-waters-house .btn {
    background-color: #00adee;
    color: white;
}
.blue-waters-house .section-padding.Galerie {
    background-color: #206392!important;
}
.blue-waters-house .section-padding.Galerie h2.title:before ,
.blue-waters-house .video .section.section-padding h2.title:before,
.blue-waters-house .virtu .section.section-padding h2.title:before,
.blue-waters-house .section-padding.projets h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid rgb(255, 255, 255);
    width: 105px;
    margin-top: 18px;
}
.blue-waters-house .section-padding.Galerie button.btn {
    background-color: #fff;
    color: #206392;
}
.blue-waters-house .plans.section-padding h2.title:before,
.blue-waters-house .section-padding.atouts h2.title:before ,
.blue-waters-house .section-padding.maps h2.title:before {
    border: 2px solid rgb(32, 99, 146);
}
.blue-waters-house .plans.section-padding{
    background-color: #fff;

}
.blue-waters-house .video .section.section-padding {
    background-color: #206392;
}
.blue-waters-house .video .all-centre span {
    color: rgb(255, 255, 255);
    font-weight: bold;
}
.blue-waters-house .virtu .section.section-padding,.blue-waters-house .section-padding.projets{
    background-color: #206392!important;
}
.blue-waters-house #contact #form-sec {
    /* border-left: 2px solid #206392; */
    border-left: 0;
}
.blue-waters-house .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.blue-waters-house .contact-popup p.txt {
    color: #206392;
  }
  .blue-waters-house div#notregroupe-image:before,.blue-waters-house .section-padding.maps .text-center:before {
    background-image: url(../../../Images/waves.png);
  }
  .blue-waters-house div#notregroupe-image:after,.blue-waters-house .section-padding.maps .text-center:after {
    background-image: url(../../../Images/waves.png);
  }
  .blue-waters-house div#notregroupe-image:before,.blue-waters-house .section-padding.maps .text-center:after  {
    width: 80%;
    height: 80%;
    right: -60px;
  }
  .blue-waters-house div#notregroupe-image:after,.blue-waters-house .section-padding.maps .text-center:before{
 display: none;
  }
  .blue-waters-house .section-padding.maps .text-center:after  {
      left: -60%;
      bottom: -25px;
  }