/* @import url('https://primefaces.org/primereact/showcase/static/css/flags.css'); */

#logo-sec {
    margin: auto;
    display: block;
    text-align: center;
}

#form-sec {
    font-family: Montserrat, sans-serif!important;
    padding: 50px;
    background-color: white;
    /* border-left: 2px solid darkslategray; */
    border-left: 0;
}

#form-sec #btn {
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 500;
    border: 0;
    color: #fff!important;
}

#Contact {
    /* border-top: 2px solid rgb(183, 133, 62); */
    border-top: 0;
}

#btn {
    background-color: rgb(183, 133, 62);
    margin-right: 20px;
    height: 50px;
}

.inpt {
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 10px !important;
    border-color: black !important;
}

.chkinpt {
    -ms-transform: scale(1.5) !important;
    -moz-transform: scale(1.5) !important;
    -webkit-transform: scale(1.5) !important;
    -o-transform: scale(1.5) !important;
    transform: scale(1.5) !important;

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.footer-contact-bloc {
    background: #fff;
    padding: 40px;
    bottom: -90px;
    position: relative;
    box-shadow: 0 0 4px #e6e6e6;
    z-index: 9;
}

.footer-contact {
    padding: 100px 0 0;
    background-size: cover;
    background-position: center;
}

.footer-contact .footer-contact-desc {
    background: #fbfbfc;
    padding: 60px;
    height: 100%;
    position: relative;
}

.footer-contact .footer-contact-desc .footer-contact-desc-contect {
    position: absolute;
    top: 50%;
    left: 60px;
    right: 60px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.footer-contact .footer-contact-desc .footer-contact-desc-titre {
    text-transform: uppercase;
    font-size: 27px;
    letter-spacing: 1px;
}

.footer-contact .footer-contact-desc .footer-contact-desc-text {
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
}

.footer-contact .footer-contact-desc .footer-contact-desc-text::after {
    content: "";
    width: 50px;
    height: 2px;
    background: rgb(183, 133, 62);
    position: absolute;
    bottom: -30px;
    left: 0;
}

.phone-field {
    display: flex;
}

.footer-contact .footer-cotect-label {
    display: block;
    font-family: bahnschrift;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    clear: both;
    color: #808184;
}

.footer-contact .form-check-label {
    margin-bottom: 0;
    display: block;
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    font-size: 11px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    letter-spacing: 1px;
    color: #808184;
}

.footer-contact .form-check-p {
    font-size: 11px;
    color: #808184;
}

.footer-contact .col-lg-8.col-md-12.offset-lg-4 p {
    font-size: 14px;
    color: #808184;
    margin-top: 25px;
}

.country-code {
    width: 50px !important;
    padding: 5px !important;
}

.footer-contact input#exampleCheck1 {
    box-shadow: inset 0 0 4px #c3c3c3 !important;
    background-color: #ccc !important;
    border: 0 !important;
    outline: 0 !important;
}

.footer-contact select#inputCountry {
    font-size: 1rem !important;
    font-weight: 400 !important;
    color: #808184 !important;
    width: 44% !important;
}

.footer-contact #inputPhone {
    width: 40%;
}

.footer-contact .form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.footer-contact .form-control {
    border: none;
    border-bottom: 1px solid #ddddde !important;
    border-radius: 0 !important;
    padding-left: 0;
    font-size: 13px;
    cursor: pointer;
}

.footer-contact #btn {
    margin-right: 20px;
    height: 50px;
    color: rgb(183, 133, 62);
    background-color: #fff !important;
    text-transform: uppercase;
    padding: 12px 30px;
    font-size: 14px;
    letter-spacing: 2px;
    cursor: pointer;
    max-width: fit-content;
    border: 1px solid !important;
}

.submit-alert {
    color: red;
    font-size: 14px;
}

.dropdown-demo .p-dropdown {
    width: 14rem;
}

.dropdown-demo .country-item-value img.flag {
    width: 17px;
}

/* country-item css */

.country-item {
    display: flex;
    align-items: center;
}

.country-item img.flag {
    width: 18px;
    margin-right: .5rem;
}

.flag {
    vertical-align: middle;
}

span.flag {
    width: 44px;
    height: 30px;
    display: inline-block;
}

img.flag {
    width: 30px
}

.contact-dropdown {
    border: none;
    border-bottom: 1px solid #ddddde !important;
    border-radius: 0 !important;
    padding-left: 0;
    font-size: 13px;
    cursor: pointer;
}

.contact-dropdown.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding: .375rem .75rem;
}

.contact-dropdown.p-dropdown .p-dropdown-clear-icon {
    display: none;
}