.magic-house #Header {
    padding: 12px 30px;
    background-color: #73c7dc;
}
.magic-house #Header .info ,.magic-house #Header .info a{
    color: #ffffff;
    text-decoration: none;
}
.magic-house #Header2 li.item a:hover {
    color: #73c7dc;
    transition: all 0.3s ease-in-out;
}
.magic-house #Contact {
    /* border-top: 2px solid #fff; */
    border-top: 0;
}
.magic-house span.contact-popup-btn {
    color: white!important;
    background-color: #286ea9;
}
.magic-house .contact-popup div#form-sec {
    border: 3px solid #73c7dc;
}
.magic-house #btn {
    background-color: #00adee;
}
.magic-house h1 {
    color: #2a6ea7;
    border-color: #f8aa3d;
}
.magic-house .btn {
    background-color: #2a6ea7;
    color: white;
}
.magic-house .section-padding.Galerie {
    background-color: #73c7dc!important;
}
.magic-house .section-padding.Galerie h2.title:before ,
.magic-house .video .section.section-padding h2.title:before,
.magic-house .virtu .section.section-padding h2.title:before,
.magic-house .section-padding.projets h2.title:before{
    content: "";
    position: absolute;
    left: 0;
    border: 2px solid #f8aa3d;
    width: 105px;
    margin-top: 18px;
}
.magic-house .section-padding.Galerie button.btn {
    background-color: #fff;
    color: #2a6ea7;
}
.magic-house .plans.section-padding h2.title:before,
.magic-house .section-padding.atouts h2.title:before ,
.magic-house .section-padding.maps h2.title:before {
    border: 2px solid #f8aa3d;
}
.magic-house .plans.section-padding{
    background-color: #fff;

}
.magic-house .video .section.section-padding {
    background-color: #73c7dc;
}
.magic-house .video .all-centre span {
    color: rgb(255, 255, 255);
    font-weight: bold;
}
.magic-house .virtu .section.section-padding,.magic-house .section-padding.projets{
    background-color: #73c7dc!important;
}
.magic-house #contact #form-sec {
    /* border-left: 2px solid #73c7dc; */
    border-left: 0;
}
.magic-house .footer {
    background-color: #202126;
    padding: 120px 15px 15px!important;
}
.magic-house .contact-popup p.txt {
    color: #73c7dc;
  }
  .magic-house div#notregroupe-image:before,.magic-house .section-padding.maps .text-center:before {
    background-image: url(../../../Images/stars.png);
  }
  .magic-house div#notregroupe-image:after,.magic-house .section-padding.maps .text-center:after {
    background-image: url(../../../Images/stars.png);
  }
  .magic-house div#notregroupe-image:before,.magic-house .section-padding.maps .text-center:after  {
    width: 80%;
    height: 80%;

  }
  .magic-house div#notregroupe-image:after,.magic-house .section-padding.maps .text-center:before{
    width: 100%;
    height: 100%;
    bottom: -165px;
  }
  .magic-house .section-padding.maps .text-center:before ,.magic-house div#notregroupe-image:before{
    right: -140px;
    top: -165px;
  }
  .magic-house .section-padding.maps .text-center:after  {
      left: -22%;

  }